import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { BackgroundImage } from '@maxmedia/mxm-react-lib';
import { Button, TextInput } from '../../../../components';
import './styles.scss';

export function SignupForm ({ primary, slice_label='cta' }) {
  const [ customerEmail, setCustomerEmail ] = useState('');
  const [ customerEmailError, setCustomerEmailError ] = useState(null);

  const [ firstName, setFirstName ] = useState("");
  const [ firstNameError, setFirstNameError ] = useState(null);

  const [ lastName, setLastName ] = useState("");
  const [ lastNameError, setLastNameError ] = useState(null);

  const formEl = useRef(null);
  const inputEl = useRef(null);
  const { content, image, actionUrl, ctaText } = primary;
  let variationClass =`--${slice_label.toLowerCase()}`; // convert human readable variation value into css modifier class format (ie. --small/--large)

  /* Update state value used to hold email address and submit during onClick event.*/
  const handleUpdateEmailInput = (e) => {
    setCustomerEmail(e.target.value);

    if (customerEmailError && inputEl.current.checkValidity()) {
      setCustomerEmailError(null);
    }
  };

  const handleFirstNameInput = (e) => {
    setFirstName(e.target.value);

    if (firstNameError && inputEl.current.checkValidity()) {
      setFirstNameError(null);
    }
  };

  const handleLastNameInput = (e) => {
    setLastName(e.target.value);

    if (lastNameError && inputEl.current.checkValidity()) {
      setLastNameError(null);
    }
  };

  /* function handles the subscribe or sign up action according the variation of the slice.
        + 'form' = submit form to url
        + 'cta' = redirect to url
 */
  const handleSignUpAction = (e) => {
    e.preventDefault();

    if (slice_label === 'form') {
      setCustomerEmailError(null);

      if (inputEl.current.checkValidity()) {
        formEl.current.submit(); // submit form
      }

      return;
    }

    return location.href = actionUrl.url; // redirect
  };

  const handleInvalidEmail = () => setCustomerEmailError('Invalid email address');
  const handleInvalidFirstName = () => setFirstNameError('Invalid first name');
  const handleInvalidLastName = () => setLastNameError('Invalid last name');

  let button = null;
  if (actionUrl?.url && ctaText) {
    button = ( <div className={`cta-container text-center ${variationClass}`}>
      <form ref={formEl} method='POST' action={actionUrl.url}>
        <input type='hidden' name='fname' value={firstName} />
        <input type='hidden' name='lname' value={lastName} />
        <input type='hidden' name='email' value={customerEmail} />
        <Button variant="contained" onClick={handleSignUpAction}>
          {ctaText}
        </Button>
      </form>
    </div> );
  }

  let containerClassName = `signup-form-container container`;
  if (slice_label === 'form' || slice_label === 'cta') containerClassName += ' --small-medium';

  return (
    <section className={`fwrd-signup-form-component ${variationClass}`}>
      <div className={containerClassName}>
        <div className='content-container'>
          <div dangerouslySetInnerHTML={{ __html: content.html }} className={'copy-container'} />
          {button}
        </div>
        <div className='image-container'>
          <BackgroundImage
            image={image.gatsbyImageData}
            alt={image.alt ?? ''}
          />
        </div>

        <div className='signup-small-container'>
          <TextInput
            className='fname-input'
            label='First Name'
            type='fname'
            name='fname'
            onChange={handleFirstNameInput}
            error={!!firstNameError}
            helperText={firstNameError}
            onInvalid={handleInvalidFirstName}
            required
          />
          <TextInput
            className='lname-input'
            label='Last Name'
            type='lname'
            name='lname'
            onChange={handleLastNameInput}
            error={!!lastNameError}
            helperText={lastNameError}
            onInvalid={handleInvalidLastName}
            required
          />
          <TextInput
            ref={inputEl}
            className='signup-input'
            type='email'
            autoComplete='off'
            label='Your email address'
            name='email'
            onChange={handleUpdateEmailInput}
            error={!!customerEmailError}
            helperText={customerEmailError}
            onInvalid={handleInvalidEmail}
            required
          />

          {button}
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageSignupFormFields on PrismicPageDataBodySignupForm {
  id, slice_type, slice_label

  primary {
    content { html }
    image {
      gatsbyImageData
      alt
    }
    ctaText
    actionUrl { url }
    formMode
  }
}

fragment homepageSignupFormFields on PrismicHomepageDataBodySignupForm {
  id, slice_type, slice_label

  primary {
    content { html }
    image {
      gatsbyImageData
      alt
    }
    ctaText
    actionUrl { url }
    formMode
  }
}
`;
