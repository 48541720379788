import React from 'react';
import { IconCardFlag } from '../icons';

export function FeaturedFlag ({ className }) {
  return (
    <div className={`fwrd-card-flag ${className}`}>
      <IconCardFlag className="card-flag" />
    </div>
  );
}
