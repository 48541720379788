import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { JobListItem, LoadingSpinner } from '../../..';
import { formatDateStr } from '../../../../utils/format-date';
import { useMergePrismicPreviewData } from '../../../../utils/use-merge-prismic-preview-data';
import { useGeocode, haversineDistance, GEOCODE_ERROR } from './use-geocode';
import { useSearch, SEARCH_ERROR } from './use-search';

export function DriversJobsList ({
  pageStartNum = 1,
  perPage = 10,
  sortValue = 'date',
  filters = {},
  onLengthFound = () => {},
  search = null,
  queryLocation = null
}) {
  const onLengthFoundNextTick = (num) => setTimeout(() => onLengthFound(num));
  const [ searchResultsIdSet, searchResultsIdArr, doSearch ] = useSearch('job-driver_job', search);
  const [ geoLocation, doGeocode ] = useGeocode(queryLocation);

  useEffect(() => { doSearch(); }, [ doSearch ]);
  useEffect(() => { doGeocode(); }, [ doGeocode ]);

  const _data = useStaticQuery(graphql`
    query DriversJobsListQuery {
      allPrismicJob(
        filter: {data: {driverJob: {id: {glob: "*"}}}}
      ) {
        nodes {
          _previewable

          prismicId

          data {
            driverCategory
            driverDepartment
            driverExperience
            driverHomeType
            driverLicenseType
            driverType
            driverJob {
              title, description
              referencenumber, date
              city, state, country
              url
              geoLocation { lat, lng }
            }
          }
        }
      }
    }
  `);

  const data = useMergePrismicPreviewData(_data);

  if ((search && !searchResultsIdSet) || (queryLocation && !geoLocation)) {
    onLengthFoundNextTick(0);
    return ( <div className="job-list"><LoadingSpinner /></div> );
  }

  const items = data.allPrismicJob.nodes.filter((node) => {
    for (let key in filters) {
      const filter = filters[key];

      if (filter?.size && !filter.has(node.data[key])) return false;
    }

    return true;
  }).filter((node) => {
    if (!search) return true;
    if (!searchResultsIdSet) return false;
    if (searchResultsIdSet === SEARCH_ERROR) return true;

    return searchResultsIdSet.has(node.prismicId);
  }).filter((node) => {
    if (!queryLocation) return true;
    if (!geoLocation) return false;
    if (geoLocation === GEOCODE_ERROR) return true;

    if (node.data.driverJob.geoLocation) {
      node.geoLocationDistance = haversineDistance(geoLocation, node.data.driverJob.geoLocation);
    } else {
      node.geoLocationDistance = Number.MAX_SAFE_INTEGER;
    }

    return node.geoLocationDistance < 500000; // within 500 km
  }).sort((a, b) => {
    if (search && sortValue === 'relevance') {
      return searchResultsIdArr.indexOf(b.prismicId) - searchResultsIdArr.indexOf(a.prismicId);
    }

    if (geoLocation && sortValue === 'distance') {
      return a.geoLocationDistance - b.geoLocationDistance;
    }

    if (sortValue === 'date' || sortValue === 'relevance' || sortValue === 'distance') {
      return Date.parse(a.data.driverJob.date) - Date.parse(b.data.driverJob.date);
    }

    console.warn(`Invalid sortValue: ${sortValue}`);
    return 0;
  });

  onLengthFoundNextTick(items.length);

  const itemsRendered = items
    .slice(pageStartNum - 1, pageStartNum - 1 + perPage)
    .map((node, index) => {
      const summaryData = [
        { label: 'Driver Category', value: node.data.driverCategory },
        { label: 'Driver Type', value: node.data.driverType },
        { label: 'Experience', value: node.data.driverExperience },
        { label: 'Department', value: node.data.driverDepartment },
        { label: 'License Type', value: node.data.driverLicenseType },
        { label: 'Home Type', value: node.data.driverHomeType }
      ];

      const { driverJob } = node.data;

      const city = [
        driverJob.city, driverJob.state, driverJob.country
      ].filter(s => s).join(', ');

      return (
        <JobListItem
          key={index}
          listingOrigin="careers"
          id={driverJob.referencenumber}
          title={driverJob.title}
          employer="Forward Air"
          city={city}
          postedAt={formatDateStr(driverJob.date)}
          fullDescription={driverJob.description}
          summaryData={summaryData}
          applyUrl={driverJob.url}
        />
      );
    });

  return ( <div className="job-list">{itemsRendered}</div> );
}
