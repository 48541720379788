import React from 'react';
import { CardContent, IconButton } from '@mui/material';
import { BookmarkIcon, MenuDotsIcon } from '../icons';
import { formatDateStr } from '../../utils/format-date';

export function ActionBar ({
  dateStamp,
  readTime
}) {
  const renderDate = (dateStr) => {
    return <span className="date-stamp">{ formatDateStr(dateStr) }</span>;
  };

  const readTimeRendered = (timeStr) => {
    if (timeStr) {
      const buildTime = <span className="read-time">{ timeStr }</span>;
      return buildTime;
    }
  };

  let actionSeparator;
  if (dateStamp && readTime) {
    actionSeparator = " - ";
  }

  return (
    <CardContent className="fwrd-row-actions">
      <div className="actions-date">
        { renderDate(dateStamp) }
        { actionSeparator }
        { readTimeRendered(readTime) }
      </div>
      {/* <div className="actions-icons">
        <IconButton aria-label="bookmark" size="large">
          <BookmarkIcon />
        </IconButton>
        <IconButton aria-label="menu" size="large">
          <MenuDotsIcon />
        </IconButton>
      </div> */}
    </CardContent>
  );
}
