import React, { useState, useCallback, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Button } from '../../..';
import { IconPlus, IconFilterList } from '../../../icons';
import { JobFiltersModal } from './job-filters-modal';

export function DriversJobFilters ({ selectedFilters }) {
  const data = useStaticQuery(graphql`
    query DriversJobFiltersQuery {
      sitePlugin(name: {eq: "gatsby-source-prismic"}) {
        pluginOptions {
          schemas {
            job {
              Drivers {
                driverCategory: driver_category {
                  config { options, label }
                }
                driverType: driver_type {
                  config { options, label }
                }
                driverExperience: driver_experience {
                  config { options, label }
                }
                driverDepartment: driver_department {
                  config { options, label }
                }
                driverLicenseType: driver_license_type {
                  config { options, label }
                }
                driverHomeType: driver_home_type {
                  config { options, label }
                }
              }
            }
          }
        }
      }
    }
  `);

  const filters = data.sitePlugin.pluginOptions.schemas.job.Drivers;

  const [ modalOpen, setModalOpen ] = useState(false);

  const handleModalClose = useCallback(() => {
    setTimeout(setModalOpen(false), 500);
  }, []);

  let modal = null;
  if (modalOpen) {
    modal = (
      <JobFiltersModal
        filters={filters}
        selectedFilters={selectedFilters}
        onClose={handleModalClose}
        openGroup={modalOpen}
      />
    );
  }

  const buttons = useMemo(() => {
    return Array.from(Object.keys(filters)).map((key, index) => {
      const { label } = filters[key].config;

      return (
        <Button
          key={index}
          variant="contained"
          startIcon={<IconPlus />}
          onClick={() => setModalOpen(key)}
        >
          {label}
        </Button>
      );
    });
  }, [ filters ]);

  return (
    <div className="filter-buttons">
      <Button
        variant="outlined"
        className="square"
        onClick={() => setModalOpen(true)}
      >
        <IconFilterList />
      </Button>
      {buttons}
      {modal}
    </div>
  );
}
