/*globals process*/
import { useState, useCallback } from 'react';

const GOOGLE_API_KEY = process.env.GATSBY_GOOGLE_API_KEY;

export const GEOCODE_ERROR = Symbol.for('SEARCH_ERROR');

export function useGeocode (address) {
  const [ geoLocation, setGeoLocation ] = useState(null);

  const doGeocode = useCallback(async () => {
    if (!address) return;

    const q = new URLSearchParams({ key: GOOGLE_API_KEY, address });

    try {
      const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?${q}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      });

      if (!res.ok) {
        setGeoLocation(GEOCODE_ERROR);
        throw new Error(`Error! status: ${res.status}`);
      }

      const data = await res.json();

      if (data.status !== 'OK' || data.results.length < 1) {
        return setGeoLocation(GEOCODE_ERROR);
      }

      return setGeoLocation(data.results[0].geometry.location);
    } catch (err) {
      console.error(err);
    }
  }, [ address ]);

  return [ geoLocation, doGeocode ];
}

const EART_RADIUS_M = 6371000; // meters

function degToRad (deg) {
  return deg * Math.PI / 180;
}

export function haversineDistance (geoA, geoB) {
  const latARad = degToRad(geoA.lat);
  const latBRad = degToRad(geoB.lat);
  const latAngleRad = degToRad(geoB.lat - geoA.lat);
  const lngAngleRad = degToRad(geoB.lng - geoA.lng);

  const latHav = Math.pow(Math.sin(latAngleRad/2), 2);
  const lngHav = Math.pow(Math.sin(lngAngleRad/2), 2);

  const a = latHav + Math.cos(latARad) * Math.cos(latBRad) * lngHav;

  return EART_RADIUS_M * 2 * Math.pow(Math.atan(Math.sqrt(a), Math.sqrt(1 - a)), 2);
}
