import React, { useState, useMemo, useCallback, useEffect } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { LinkWrapper } from "@maxmedia/mxm-react-lib";
import { ActionBar, Button, ContentCard, CtaCard, SuccessChipsList, CollectionFilters } from '../index';
import { SignupForm } from '../slice-zone/slices/signup-form';
import defaultPhoto from '../../images/cta-image.jpg';


export function NewsBody ({
  data,
  featuredNewsArticles,
  pressReleaseNewsArticles,
  blogNewsArticles
}) {
  const renderArticles = (arr) => {
    return (
      arr?.map( (article, index) => (
        <div key={index} className='logo-image'>
          <ContentCard
            headline={ article.data.title.text }
            category={ article.data.category }
            image={ article.data.image }
            readTime={ article.data.readTime }
            bodyCopy={ article.data.description.text }
            dateStamp={ article.data.publishDate }
            featured={ article.tags.includes('featured') ? true : false }
            url={ article.url }
          >
            <ActionBar
              dateStamp={ article.data.publishDate }
              readTime={ article.data.readTime }
            />
          </ContentCard>
        </div>
      ))
    );
  };

  const signupFormPrimary = useMemo(() => {
    return {
      content: data.signupFormContent,
      image: data.signupFormImage,
      actionUrl: data.signupFormActionUrl,
      ctaText: data.signupFormCtaText
    };
  }, [ data ]);

  return (
    <>
      <div className="fwrd-news-container">
        <div className="news-section-container --dark">
          <div className='container news-container'>
            <div className='fwrd-news-header'>Featured News</div>
            <div className='fwrd-news-content'>
              { renderArticles(featuredNewsArticles) }
            </div>
            <div className="news-section-cta">
              <LinkWrapper url='/resources/featured' className="news-view-all-link">
                <Button
                  variant="contained"
                  className='fwrd-button'
                  disableRipple
                  // onClick={ handleLoadMore }
                >
                  View all news
                </Button>
              </LinkWrapper>
            </div>
          </div>
        </div>
        <div className="news-section-container --light">
          <div className='container news-container'>
            <div className='fwrd-news-header'>Press Releases</div>
            <div className='fwrd-news-content'>
              { renderArticles(pressReleaseNewsArticles) }
            </div>
            <div className="news-section-cta">
              <LinkWrapper url='/resources/press-releases' className="news-view-all-link">
                <Button
                  variant="contained"
                  className='fwrd-button'
                  disableRipple
                  // onClick={ handleLoadMore }
                >
                  View all press releases
                </Button>
              </LinkWrapper>
            </div>
          </div>
        </div>
        <div className="news-section-container --dark">
          <div className='container news-container'>
            <div className='fwrd-news-header'>Forward Blog</div>
            <div className='fwrd-news-content'>
              { renderArticles(blogNewsArticles) }
            </div>
            <div className="news-section-cta">
              <LinkWrapper url='/resources/blog' className="view-all-link">
                <Button
                  variant="contained"
                  className='fwrd-button'
                  disableRipple
                  // onClick={ handleLoadMore }
                >
                  View all blog posts
                </Button>
              </LinkWrapper>
            </div>
          </div>
        </div>
        <div className="signup-form">
          <SignupForm primary={signupFormPrimary} slice_label={data.signupFormType} />
        </div>
        {/* <div className="news-section-container">
          <div className='container news-container'>
            <div className='fwrd-news-header'>Facebook Feed</div>
          </div>
        </div> */}
      </div>
    </>
  );
}
