import React, { useState, useRef, useCallback } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { LinearProgress } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { Button } from '..';
// import './styles.scss';

async function setupUpload (filename) {
  const q = new URLSearchParams({ filename });
  const res = await fetch(`https://api.dev.www.forwardair.mxmcloud.com/claims/upload?${q}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  });

  if (!res.ok) throw new Error(`Failed to setup upload (${res.status})`);

  return await res.json();
}

export function UploadExample () {
  const fileRef = useRef(null);
  const [ progress, setProgress ] = useState(null);

  const handleProgress = useDebouncedCallback((size, ev) => {
    setProgress(ev.loadedBytes / size * 100);
  }, []);

  const handleClick = useCallback(async () => {
    const file = fileRef.current.files[0];

    const { blobClientUrl, container, path } = await setupUpload(file.name);

    const blobServiceClient = new BlobServiceClient(blobClientUrl);
    const containerClient = blobServiceClient.getContainerClient(container);
    const blockBlobClient = containerClient.getBlockBlobClient(path);

    const blobHTTPHeaders = { blobContentType: file.type };

    blockBlobClient.uploadData(file, {
      blobHTTPHeaders,
      onProgress: handleProgress.bind(null, file.size)
    });
  }, [ handleProgress ]);

  return (
    <div className="fwrd-upload-example">
      <LinearProgress variant="determinate" value={progress ?? 0} />
      <form>
        <input type="file" className="file" ref={fileRef} />
        <Button
          variant="contained"
          onClick={handleClick}
          disabled={progress}
        >Upload</Button>
      </form>
    </div>
  );
}
