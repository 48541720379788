import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Chip, TextField, InputAdornment } from "@mui/material";
import { CloseIcon } from "../icons";
import { Button } from "../../components";
import './styles.scss';

function isValidTrackingNumber (num) {
  return /^[a-z0-9-_.]{3,}$/i.test(num.trim());
}

export function InputFieldWithChips ({
  selectedTags,
  handleButtonClick,
  tags,
  ...other }) {
  const [ inputValue, setInputValue ] = useState("");
  const [ selectedItem, setSelectedItem ] = useState([]);
  const [ isPaste, setIsPaste ] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setSelectedItem(tags);
  }, [ tags ]);
  useEffect(() => {
    selectedTags(selectedItem);
  }, [ selectedItem, selectedTags ]);

  function convertValueToNewChip () {
    const newSelectedItem = [ ...selectedItem ];
    const duplicatedValues = newSelectedItem.indexOf(
      inputValue.trim()
    );

    if (duplicatedValues !== -1) {
      setInputValue("");
      return;
    }

    if (!isValidTrackingNumber(inputValue.trim())) return;

    newSelectedItem.push(inputValue.trim());
    setSelectedItem(newSelectedItem);
    setInputValue("");
  }

  function removeLastChip () {
    if (
      selectedItem.length &&
      !inputValue.length
    ) {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
      inputRef.current.focus();
    }
  }

  function onKeyDown (ev) {
    if (( ev.key === "Enter" || ev.key === ' ' ) && isValidTrackingNumber(ev.target.value)) {
      convertValueToNewChip();
      inputRef.current.focus();
    } else if (ev.key === "Backspace") {
      removeLastChip();
      inputRef.current.focus();
    }
  }

  function handleAddClick (ev) {
    if (ev.type === "click" && isValidTrackingNumber(ev.target.value)) {
      convertValueToNewChip();
    }
    inputRef.current.focus();
  }

  const handleDelete = (item) => {
    const newSelectedItem = [ ...selectedItem ];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
    inputRef.current.focus();
  };

  function handleInputChangeFromPaste (ev) {
    setIsPaste(false);

    const newSelectedItem = [ ...selectedItem ];

    const newValues = ev.target.value.trim().split(/[,;\s]+/);

    const newInputValue = [];

    for (let val of newValues) {
      if (newSelectedItem.includes(val)) continue;

      if (isValidTrackingNumber(val)) {
        newSelectedItem.push(val);
      } else {
        newInputValue.push(val);
      }
    }

    setSelectedItem(newSelectedItem);
    setInputValue(newInputValue.join(' '));
    inputRef.current.focus();
  }

  function handleInputChange (ev) {
    if (isPaste) return handleInputChangeFromPaste(ev);

    setInputValue(ev.target.value);
  }

  function handleClick (ev) {
    if (ev.target.classList.contains('MuiInputAdornment-root')) {
      inputRef.current.focus();
    }
  }

  return (
    <div className="fwrd-shp-trk-bar">
      <div className="input-w-chip">
        <TextField
          className="text-input"
          value={inputValue}
          onClick={handleClick}
          inputRef={inputRef}
          InputProps= {
            {
              onKeyDown: onKeyDown,
              onPaste: () => setIsPaste(true),
              startAdornment: selectedItem.map((item, index) => (
                <Chip
                  key={index}
                  tabIndex={-1}
                  label={item}
                  onDelete={ handleDelete.bind(null, item) }
                  deleteIcon={<CloseIcon />}
                  className="del-chip"
                >
                </Chip>
              )),
              onChange: ev => {
                handleInputChange(ev);
              },
              endAdornment: <InputAdornment
                position = "end"
                className="input-end">
                <Button
                  value={inputValue}
                  onClick={handleAddClick}
                  //when the end icon is active the onClick handler will only work when the svg it contains is not clciked.
                  // startIcon={<IconPlus onClick={testOnClick} value={inputValue}/>}
                >
                  <span className="add-icon">
                    +
                  </span>
                  Add
                </Button>
              </InputAdornment>
          }
          }
          {...other}
        >
        </TextField>
      </div>
      <Button variant="contained" className="trk-shp-btn" onClick={handleButtonClick}>Track Shipments</Button>
    </div>
  );
}
InputFieldWithChips.defaultProps = {
  tags: []
};
InputFieldWithChips.propTypes = {
  selectedTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string)
};
