import React, { useState } from 'react';
import { AlertBar, MenuDropdown } from '..';
import { LocationIcon, ChevronRightIcon, TrackingActivity, TrackingActivityStart, IconLinkArrow } from '../icons';
import { TrackingModal } from './tracking-modal';

export function TrackingResult ({ listingContent, trackingResult, statuses }) {
  const [ sortBy, setSortBy ] = useState(null);
  const [ sortDesc, setSortDesc ] = useState(true);
  const [ modalActive, setModalActive ] = useState(false);
  const [ resultDetails, setResultDetails ] = useState({});

  // toggle sort direction
  const setSortDirection = (selectOption) => {
    if (sortBy === selectOption) {
      setSortDesc(!sortDesc);
    } else {
      setSortDesc(true);
    }
  };

  // set up the items for sorting the shipment listing result
  const menuListItems = [
    {
      handleClick: () => {
        setSortDirection('RecordDate');
        setSortBy('RecordDate');
      },
      text: 'Most Recent'
    },
    {
      handleClick: () => {
        setSortDirection('LastHandled');
        setSortBy('LastHandled');
      },
      text: 'Terminal'
    },
    {
      handleClick: () => {
        setSortDirection('LastStatusRecordDate');
        setSortBy('LastStatusRecordDate');
      },
      text: 'Date Shipped'
    },
    {
      handleClick: () => {
        setSortDirection('Destination');
        setSortBy('Destination');
      },
      text: 'Destination'
    },
    {
      handleClick: () => {
        setSortDirection('Origin');
        setSortBy('Origin');
      },
      text: 'Origin'
    },
    {
      handleClick: () => {
        setSortDirection('EstimatedAvailableDate');
        setSortBy('EstimatedAvailableDate');
      },
      text: 'Delivery Date'
    }
  ];

  const handleCloseModal = () => {
    setModalActive(false);
  };

  let sortedResult = trackingResult;

  if (sortedResult.results) {
    sortedResult.results.sort((a, b) => {
      let aCopy, bCopy;
      if (sortBy === 'RecordDate' || sortBy === 'EstimatedAvailableDate' || sortBy === 'LastStatusRecordDate') {
        if (sortBy === 'LastStatusRecordDate') {
          aCopy = a.Statuses[a.Statuses.length - 1].RecordDate.split(/[: /]/);
          bCopy = b.Statuses[b.Statuses.length - 1].RecordDate.split(/[: /]/);
        } else {
          aCopy = a[sortBy].split(/[: /]/);
          bCopy = b[sortBy].split(/[: /]/);
        }
        aCopy = aCopy[2] + aCopy[0] + aCopy[1] + aCopy[3] + aCopy[4];
        bCopy = bCopy[2] + bCopy[0] + bCopy[1] + bCopy[3] + bCopy[4];
      }

      if (sortDesc) {
        if (sortBy === 'RecordDate' ||
          sortBy === 'EstimatedAvailableDate' ||
          sortBy === 'LastStatusRecordDate') {
          return aCopy > bCopy ? -1 : aCopy < bCopy ? 1 : 0;
        }

        if (a[sortBy] < b[sortBy]) {
          return 1;
        }

        if (a[sortBy] > b[sortBy]) {
          return -1;
        }
      } else {
        if (sortBy === 'RecordDate' ||
          sortBy === 'EstimatedAvailableDate' ||
          sortBy === 'LastStatusRecordDate') {
          return aCopy > bCopy ? 1 : aCopy < bCopy ? -1 : 0;
        }

        if (a[sortBy] < b[sortBy]) {
          return -1;
        }

        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
      }

      return 0;
    });
  }

  return (
    Object.keys(trackingResult).length > 0 && Object.keys(trackingResult.errors).length > 0 && Object.keys(sortedResult.results).length > 0 &&
      <div className="fwrd-listings">
        <div className="container">
          {
            Object.keys(trackingResult.errors).length > 0 &&
            sortedResult.errors.map((error, i) => {
              return (
                <AlertBar
                  alertId={`alert-bar-${i}`}
                  key={i}
                >
                  {error}
                </AlertBar>
              );
            })
          }

          {
            Object.keys(sortedResult.results).length > 0 &&
            <>
              <div className="fwrd-listings-grid">
                <div className="listings-column column-main">
                  <div
                    className="listing-info"
                    dangerouslySetInnerHTML={{ __html: listingContent }}>
                  </div>
                </div>
                <div className="listings-column column-filter">
                  <MenuDropdown
                    buttonText="Sort by"
                    listItems={menuListItems} />
                </div>
              </div>
              <div className="fwrd-listing-result">
                {
                  sortedResult.results.map((result, i) => {
                    const shippingStatus = statuses.find(
                      x => x.statusCode === result.CurrentStatusCode
                    );
                    return (
                      <a className="listing cursor-pointer" key={i}
                        href="#"
                        onClick={
                          () => {
                            setResultDetails(result);
                            setModalActive(true);
                          }
                        }
                        onKeyDown={null}
                        role="button"
                        tabIndex="0"
                        aria-label="Open Shipping Details"
                        data-row={result}
                      >
                        <div className="listing-inner" style={
                          {
                            borderColor: shippingStatus.statusColor
                          }
                        }>
                          <div className="listing-col">
                            <div className="shipment-number">
                              <div className="inputformassistive-text">Shipment Number</div>
                              <div className="shipment-number">{result.AirbillNumber}</div>
                            </div>
                            <div className="status">
                              <div className="inputformassistive-text">Status</div>
                              <div className="header modifier --small text-uppercase" style={
                                {
                                  color: shippingStatus.statusColor
                                }
                              } >
                                {shippingStatus.generalizedDescription}
                              </div>
                            </div>
                          </div>
                          <div className="listing-col shipping-tracking">
                            <div className="current-location">
                              <div className="icon-wrapper"><LocationIcon /></div>
                              <div>
                                <div className="inputformassistive-text">Current Location</div>
                                <div className="copy --small text-black">{result.CurrentStatusDescription} at {result.LastHandled} </div>
                              </div>
                            </div>
                          </div>
                          <div className="listing-col shipping-tracking">
                            <div className="origin-location">
                              <div className="icon-wrapper"><TrackingActivityStart /></div>
                              <div>
                                <div className="inputformassistive-text">Origin</div>
                                <div className="copy --small text-black">{result.originName}</div>
                              </div>
                            </div>
                          </div>
                          <div className="desktop-icon-wrapper">
                            <IconLinkArrow color="#989a98" className="icon-action-link" />
                          </div>
                          <div className="listing-col shipping-tracking">
                            <div className="destination-location">
                              <div className="icon-wrapper"><TrackingActivity /></div>
                              <div>
                                <div className="inputformassistive-text">Destination</div>
                                <div className="copy --small text-black">{result.destinationName}</div>
                              </div>
                            </div>
                          </div>
                          <div className="listing-col">
                            <div className="amount">
                              <div className="inputformassistive-text">Amount</div>
                              <div className="copy --small text-black">
                                {result.Pieces}
                                {
                                  result.Pieces === 1
                                    ? ' item '
                                    : ' items '
                                }
                                x {result.Weight}
                                {
                                  result.Weight === 1
                                    ? ' lb'
                                    : ' lbs'
                                }
                              </div>
                            </div>
                          </div>
                          <div className="desktop-icon-wrapper">
                            <ChevronRightIcon className="chevron-right"/>
                          </div>
                        </div>
                      </a>
                    );
                  })
                }
              </div>
            </>
          }
        </div>
        {
          modalActive &&
            <TrackingModal
              handleCloseModal={handleCloseModal}
              resultDetails={resultDetails}
              statuses={statuses}
            />
        }
      </div>
  );
}
