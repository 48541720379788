import { useState, useCallback } from 'react';

export const SEARCH_ERROR = Symbol.for('SEARCH_ERROR');

export function useSearch (type, search) {
  const [ searchResultsIdSet, setSearchResultsIdSet ] = useState(null);

  const doSearch = useCallback(async () => {
    if (!search) return;

    try {
      const q = new URLSearchParams({ limit: 500, type: type, q: search, only: 'id' });
      const res = await fetch(`https://api.dev.www.forwardair.mxmcloud.com/search/search?${q}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      });

      if (!res.ok) {
        setSearchResultsIdSet(SEARCH_ERROR);
        throw new Error(`Error! status: ${res.status}`);
      }

      const { results } = await res.json();

      setSearchResultsIdSet(new Set(results.map(r => r.document.id)));
    } catch (err) {
      console.error(err);
    }
  }, [ type, search ]);

  const searchResultsIdArr = searchResultsIdSet ? Array.from(searchResultsIdSet) : null;

  return [ searchResultsIdSet, searchResultsIdArr, doSearch ];
}
