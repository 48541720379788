import React from 'react';
import { graphql } from 'gatsby';
import { Img, LinkWrapper } from '@maxmedia/mxm-react-lib';
import './styles.scss';

import { columnsContentPrismicClasses } from '../columns-content';

export function ContentAndFeaturesBlocks ({
  primary,
  items,
  slice_label
}) {
  const { content } = primary;

  const sliceBlocksRendered = () => {
    if (!items?.length) return null;

    return items.map( (item, i) => {
      const { icon, label, link } = item;

      if (item.heading.text) {
        return (
          <div
            key={i}
            className={`heading-container ${i === 0 ? 'opening-header' : ''}`}
            dangerouslySetInnerHTML={{ __html: item.heading.html }}
          />
        );
      }

      const itemClassName = [ 'grid-item' ];

      if (slice_label === "large_icons") {
        itemClassName.push('grid-item__features');
      } else {
        itemClassName.push('grid-item__columns');
      }

      const itemContent = (
        <>
          <Img
            image={ icon.gatsbyImageData }
            alt={ icon.alt ? icon.alt : "" }
            className={`grid-item__icon${ slice_label === "large_icons" ? '-large' : ''}`}
          />
          <div
            className="grid-item__content"
            dangerouslySetInnerHTML={{ __html: label.html }}
          />
        </>
      );

      if (link?.url) {
        return (
          <LinkWrapper key={i} className={itemClassName.join(' ')} url={link.url}>
            {itemContent}
          </LinkWrapper>
        );
      }

      return (
        <div key={i} className={itemClassName.join(' ')}>
          {itemContent}
        </div>
      );
    });
  };

  const sliceContentRendered = () => {
    if (!content.html) return null;

    return (
      <div
        className="slice-col content-col wysiwyg-slice-content"
        dangerouslySetInnerHTML={{ __html: content.html }}
      />
    );
  };

  const prismicClasses = columnsContentPrismicClasses(primary);

  const containerClasses = () => {
    if (slice_label !== "large_icons") {
      return "fwrd-columns-grid-container";
    }

    return "fwrd-features-grid-container";
  };

  return (
    <section className={`fwrd-slice-columns-squares module-padding ${prismicClasses}`}>
      <div className={`slice-wrapper container ${slice_label === "large_icons" ? "alt-layout" : ""}`}>
        <div className="content-wrapper">
          { sliceContentRendered() }
          <div className="slice-col grid-col">
            <div className={ containerClasses() }>
              { sliceBlocksRendered() }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageContentAndFeaturesBlocksFields on PrismicPageDataBodyContentAndFeaturesBlocks {
  id, slice_type, slice_label

  primary {
    backgroundColor
    diagonalPosition
    diagonalColor
    raisedContentBox
    content { html }
  }

  items {
    icon { gatsbyImageData }
    altIcon { gatsbyImageData }
    label { html }
    heading { text, html }
    link { url, target }
  }
}
`;
