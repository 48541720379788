import React from 'react';
import { ArticleContent } from './slices/article-content';
import { ColumnsContent } from './slices/columns-content';
import { ContentAndFeaturesBlocks } from './slices/content-and-features-blocks';
import { CustomCode } from './slices/custom-code';
import { EndToEnd } from './slices/end-to-end';
import { FactsAndFigures } from './slices/facts-and-figures';
import { FAQs } from './slices/faqs';
import { FuelSurcharges } from './slices/fuel-surcharges';
import { Header } from './slices/header';
import { Hero } from './slices/hero';
import { ImageSlider } from './slices/image-slider';
import { JobsCategoriesCards } from './slices/jobs-categories-cards';
import { JobListings } from './slices/job-listings';
import { Leadership } from './slices/leadership';
import { LoginForm } from './slices/login-form';
import { LoginLanding } from './slices/login-landing';
import { LogoFarm } from './slices/logo-farm';
import { NavigationGrid } from './slices/navigation-grid';
import { RelatedArticles } from './slices/related-articles';
import { ServiceMapOverview } from './slices/service-map-overview';
import { ServiceNavigationCards } from './slices/service-navigation-cards';
import { ServicesOverview } from './slices/services-overview';
import { SignupForm } from './slices/signup-form';
import { SliderSection } from './slices/slider-section';
import { TestimonialsSlider } from './slices/testimonials-slider';
import { ValuePropositions } from './slices/value-propositions';

const SLICE_COMPONENTS = {
  article_content: ArticleContent,
  columns_content: ColumnsContent,
  content_and_features_blocks: ContentAndFeaturesBlocks,
  custom_code: CustomCode,
  end_to_end: EndToEnd,
  facts_and_figures: FactsAndFigures,
  faqs: FAQs,
  fuel_surcharges: FuelSurcharges,
  header: Header,
  hero: Hero,
  image_slider: ImageSlider,
  jobs_categories_cards: JobsCategoriesCards,
  job_listings: JobListings,
  leadership: Leadership,
  login_form: LoginForm,
  login_landing: LoginLanding,
  logo_farm: LogoFarm,
  navigation_grid: NavigationGrid,
  related_articles: RelatedArticles,
  service_map_overview: ServiceMapOverview,
  service_navigation_cards: ServiceNavigationCards,
  services_overview: ServicesOverview,
  signup_form: SignupForm,
  slider_section: SliderSection,
  testimonials_slider: TestimonialsSlider,
  value_propositions: ValuePropositions
};

export function SliceZone ({ sliceZone, url }) {
  const sliceZoneContent = sliceZone?.map((slice, index) => {
    if (!slice.slice_type) {
      console.warn(`No slice_type defined in graphql fragment for ${slice.__typename}`);
      return null;
    }

    const SliceComponent = SLICE_COMPONENTS[slice.slice_type];

    if (!SliceComponent) {
      console.warn(`Invalid slice type: ${JSON.stringify(slice)}`);
      return null;
    }

    return ( <SliceComponent {...slice} key={index} url={url} /> );
  });

  return <>{sliceZoneContent}</>;
}
