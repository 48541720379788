import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Img } from '@maxmedia/mxm-react-lib';
import { Button, Card } from '../../../../components';
import { IconLinkArrow, IconUpLinkArrow } from '../../../icons';
import './styles.scss';

function HighlightLeader (
  { highlightImage,
    name: name_,
    expandCtaText,
    collapseCtaText,
    title1,
    bio,
    highlightBio }
) {
  const [ isExpanded, setExpanded ] = useState(false);

  const handleExpandBio = () => {
    setExpanded(!isExpanded);
  };

  let bioRendered=null;
  if (isExpanded) {
    bioRendered = (
      <div className = "bio-container">
        <div className='left-border'></div>
        <div className="expanded-bio" dangerouslySetInnerHTML={{ __html: bio.html }}/>
      </div>
    );
  }

  return (
    <Card
      square={true}
      avImage={ <Img image={highlightImage} alt={highlightImage.alt} className="highlight-card-image"/>}
      heading={name_}
      subheading={title1}
      className={`large mainLeader ${isExpanded ? "cardOpen" : null}`}
      ctaButton={
        <Button
          variant="text"
          className={`no-padding ${isExpanded ? "iconCloseClass" : null}`}
          startIcon={<IconLinkArrow/>}
          disableRipple
          onClick={handleExpandBio}
        >
          {isExpanded ? collapseCtaText : expandCtaText}
        </Button>
      }
    >
      <div className='highlight-bio'>
        <div dangerouslySetInnerHTML={{ __html: highlightBio.html }}/>
      </div>
      {bioRendered}
    </Card>
  );
}

function TeamLeader ({
  image,
  name: name_,
  collapseCtaText,
  title1,
  bio
}) {
  const [ isExpanded, setExpanded ] = useState(false);

  const handleExpandBio = () => {
    setExpanded(!isExpanded);
  };

  let bioRendered=null;
  let showCloseButton=null;
  if (isExpanded) {
    bioRendered = (
      <div className = "bio-container">
        <div className='left-border'></div>
        <div className="expanded-bio" dangerouslySetInnerHTML={{ __html: bio.html }}/>
      </div>
    );
    showCloseButton= (
      <Button
        variant="text"
        className="no-padding"
        startIcon={<IconUpLinkArrow/>}
        disableRipple
      >
        {collapseCtaText}
      </Button>);
  }

  return (
    <Card
      square={true}
      avImage={ <Img image={image} alt={image.alt} className="leader-image"/>}
      className ={`xtra-small ${isExpanded ? "cardOpen" : null}`}
      heading = {name_}
      subheading={title1}
      onClick={handleExpandBio}
    >
      {bioRendered}
      {showCloseButton}
    </Card>
  );
}

export function Leadership ({ primary, items }) {
  const teamRendered = items.filter((item) => {
    return !item.highlightImage.gatsbyImageData;
  }).map((item, index) => {
    return (
      <div key={index} className="leader-container flex-item">
        <TeamLeader {...item} {...primary} />
      </div>
    );
  });

  const highlightRendered = items.filter((item) => {
    return item.highlightImage.gatsbyImageData;
  }).map((item, index) => {
    return (
      <div className="highlight-leader flex-item" key={index}>
        <Img image={item.highlightImage.gatsbyImageData} alt={item.highlightImage.alt} className="highlight-image"/>
        <HighlightLeader {...item} {...primary} />
      </div>
    );
  });

  return (
    <section className='slice-module fwrd-leadership-container'>
      <div className='leadership-team__inner container'>
        <div className="team-content" dangerouslySetInnerHTML={{ __html: primary.content.html }}/>
        { highlightRendered }
      </div>
      <div className='leadership-team__inner container'>
        { teamRendered }
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageLeadershipFields on PrismicPageDataBodyLeadership {
  id, slice_type

  primary {
    content { html }
    expandCtaText
    collapseCtaText
  }

  items {
    image { gatsbyImageData }
    highlightImage { gatsbyImageData }
    name
    title1
    highlightBio { html }
    bio { html }
  }
}
`;
