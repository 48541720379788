import React from 'react';
import { graphql } from 'gatsby';
import { Img, LinkWrapper } from '@maxmedia/mxm-react-lib';
import { Button } from '../../../../components';
import './styles.scss';
import serviceMapImg from '../../../../images/service-map.png';

export function EndToEnd ({ primary, items }) {
  const {
    content, mapTitle, mapLink, mapLinkText
  } = primary;

  const sliceBlocksRendered = () => {
    if (!items?.length) return null;

    const blocksGroup = items.map( (item, i) => {
      const { icon, label, link } = item;
      const itemContent = (
        <>
          <Img
            image={ icon?.gatsbyImageData }
            alt={ icon?.alt ? icon.alt : "" }
            className="grid-item__icon"
          />
          <div
            className="grid-item__content"
            dangerouslySetInnerHTML={{ __html: label?.html }}
          />
        </>
      );

      return (
        <LinkWrapper key={i} className="grid-item grid-item__columns" url={ link?.url }>
          { itemContent }
        </LinkWrapper>
      );
    });

    return (
      <div className="large-icon-grid-container content-blocks">
        { blocksGroup }
      </div>
    );
  };

  return (
    <section className="fwrd-end-to-end">
      <div className="container --small">

        <div className="end-to-end-content">
          <div dangerouslySetInnerHTML={{ __html: content?.html }}></div>
          { sliceBlocksRendered() }
        </div>

        <div className="end-to-end-image">
          <div dangerouslySetInnerHTML={{ __html: mapTitle?.html }}></div>
          <img className="service-map" src={serviceMapImg} alt="" />
          <Button
            href={ mapLink.url }
            className="end-to-end-image__button">
            { mapLinkText }
          </Button>
        </div>

      </div>
    </section>
  );
}

export const query = graphql`
fragment homepageEndToEndFields on PrismicHomepageDataBodyEndToEnd {
  id
  slice_type
  primary {
    content {  html }
    mapTitle { html }
    mapLink { url }
    mapLinkText
  }
}

fragment pageEndToEndFields on PrismicPageDataBodyEndToEnd {
  id
  slice_type
  slice_label
  primary {
    content {  html }
    mapTitle { html }
    mapLink { url }
    mapLinkText
  }

  items {
    icon { gatsbyImageData }
    altIcon { gatsbyImageData }
    label { html }
    link { url }
  }
}
`;
