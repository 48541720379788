import React from 'react';
import { formatDateStr } from '../../utils/format-date';
import './styles.scss';

export function ArticleHeader ({
  date,
  readtime,
  category,
  author,
  header,
  subhead,
  actionsBarContent
}) {
  const readTimeRendered = !readtime ? null : (
    <span className="read-time">{ readtime }</span>
  );

  let actionSeparator;
  if (date && readtime) {
    actionSeparator = <p className="read-time">&nbsp;|&nbsp;</p>;
  }

  const dateTimeRendered = !date ? null : (
    <p className="read-time">{ formatDateStr(date) }</p>
  );

  let categoryClassName = [ 'category' ];
  const categoryRendered = !category ? null : (
    <div className={categoryClassName.join(' ')}>
      <span>{ category }</span>
    </div>
  );

  const titleRendered = !header ? null : (
    <div
      className="header"
      dangerouslySetInnerHTML={{ __html: header }}
    />
  );

  const subtitleRendered = !subhead ? null : (
    <div
      className="subheader"
      dangerouslySetInnerHTML={{ __html: subhead }}
    />
  );

  const authorRendered = !author ? null : (
    <p className="author">{ author }</p>
  );

  const actionsBarRendered = !actionsBarContent ? null : (
    <div className="actions-share">
      { actionsBarContent }
    </div>
  );

  return (
    <div className="fwrd-article-header">
      <div className="actions-info">
        { dateTimeRendered }
        { actionSeparator }
        { readTimeRendered }
      </div>
      { categoryRendered }
      { titleRendered }
      { subtitleRendered }
      { authorRendered }
      { actionsBarRendered }
    </div>
  );
}
