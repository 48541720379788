import React, { useMemo, useState } from "react";
import Slider from "@maxmedia/react-slick";
import { graphql } from 'gatsby';
import { Img } from '@maxmedia/mxm-react-lib';
import { IconLeftArrow, IconRightArrow } from "../../../icons";
import "./styles.scss";

function NextArrow (props) {
  const { className, style, onClick: handleClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={handleClick}
      onKeyDown={handleClick}
      role='button'
      tabIndex='0'>
      <IconRightArrow />
    </div>
  );
}

function PrevArrow (props) {
  const { className, style, onClick: handleClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={handleClick}
      onKeyDown={handleClick}
      role='button'
      tabIndex='0'>
      <IconLeftArrow />
    </div>
  );
}

export function SliderSection ({ items }) {
  const [ activeSlide, setActiveSlide ] = useState(0);
  const imageSlides = useMemo(() => {
    if (!items.length) return null;

    return items.map((item, i) => {
      return (
        <div
          className={`image-slide ${i === activeSlide ? "active" : ""}`}
          key={`slider-section-image-slide-${i}`}>
          <div className="img-container">
            <Img image={item.image} alt={item.image.alt ?? ''} />
          </div>
        </div>
      );
    });
  }, [ activeSlide, items ]);

  const slides = useMemo(() => {
    if (!items.length) return null;

    return items.map((item, i) => {
      const { content } = item;

      return (
        <div className='slide' key={`slider-section-slide-${i}`}>
          <div className='top-content'>
            <div
              className='slide-content wysiwyg-slice-content'
              dangerouslySetInnerHTML={{ __html: content.html }}
            />
          </div>
        </div>
      );
    });
  }, [ items ]);

  const sliderSettings = {
    autoplay: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    adaptiveHeight: false,
    beforeChange: (current, next) => setActiveSlide(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <section className={`fwrd-slider-section`}>
      <div className={`slider-section__inner`}>
        <div className='image-slider-container'>
          <div className='image-slides'>{imageSlides}</div>
        </div>
        <div className='content-slider-container'>
          <Slider {...sliderSettings}>{slides}</Slider>
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
  fragment homepageSliderSectionFields on PrismicHomepageDataBodySliderSection {
    id
    items {
      content {
        html
        text
      }
      image {
        alt
        gatsbyImageData
      }
    }
    slice_label
    slice_type
  }
`;
