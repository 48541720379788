import React from 'react';
import { graphql } from 'gatsby';

export function CustomCode ({ primary }) {
  return (
    <div
      className="fwrd-custom-code"
      dangerouslySetInnerHTML={{ __html: primary.content.text }}
    />
  );
}

export const query = graphql`
fragment pageCustomCodeFields on PrismicPageDataBodyCustomCode {
  id, slice_type

  primary {
    content { text }
  }
}

fragment articleCustomCodeFields on PrismicArticlesDataBodyCustomCode {
  id, slice_type

  primary {
    content { text }
  }
}

fragment homepageCustomCodeFields on PrismicHomepageDataBodyCustomCode {
  id, slice_type

  primary {
    content { text }
  }
}

fragment loginPageCustomCodeFields on PrismicLoginPageDataBodyCustomCode {
  id, slice_type

  primary {
    content { text }
  }
}

fragment trackingPageCustomCodeFields on PrismicTrackingPageDataBodyCustomCode {
  id, slice_type

  primary {
    content { text }
  }
}

fragment the404PageCustomCodeFields on Prismic404PageDataBodyCustomCode {
  id, slice_type

  primary {
    content { text }
  }
}

fragment searchPageCustomCodeFields on PrismicSearchPageDataBodyCustomCode {
  id, slice_type

  primary {
    content { text }
  }
}
`;
