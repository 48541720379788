import React from 'react';
import { IconButton, Snackbar as MuiSnackbar, Alert, AlertTitle } from '@mui/material';
import { CloseIcon } from '../icons';
import './styles.scss';

export function Snackbar ({
  title,
  onClose,
  alertIcon=false,
  children,
  ...props
}) {
  const handleClose = () => onClose();

  const alertTitleRendered = title ? (
    <AlertTitle>{ title }</AlertTitle>
  ) : null;

  const action = (
    <IconButton
      size='small'
      aria-label='close'
      onClick={ handleClose }
    >
      <CloseIcon color='#ffffff' fontSize='small' />
    </IconButton>
  );

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      className='fwrd-snackbar'
      { ...props }
    >
      <Alert
        action={ action }
        icon={ alertIcon }
        className='fwrd-alert-container'
      >
        { alertTitleRendered }
        { children }
      </Alert>
    </MuiSnackbar>
  );
}
