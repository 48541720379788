import React from 'react';
import { Pagination } from '@mui/material';

export function BulletPagination ({ count, className, ...props }) {
  return (
    <Pagination
      className={`fwrd-bullet-pagination ${className ?? ''}`}
      count={count}
      size='small'
      shape="rounded"
      siblingCount={count}
      hidePrevButton
      hideNextButton
      {...props}
    />
  );
}
