import React from 'react';
import './styles.scss';

export function JobSummary ({
  summaryItems = [],
  className = ''
}) {
  const items = summaryItems.map((item) =>
    <li key={item.label}>
      <span className="label">{item.label}:</span>
      <span className="value">{item.value}</span>
    </li>
  );
  return (
    <ul className={className + " job-summary-container horizontal"}>{items}</ul>
  );
}
