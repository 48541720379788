import React from 'react';
import Slider from "@maxmedia/react-slick";
import { graphql } from 'gatsby';
import { Button } from "../../../../components";
import { IconLinkArrow } from '../../../icons';
import './styles.scss';

export function JobsCategoriesCards ({ primary, items }) {
  const cardContentHeader = primary.heading.html;
  const cardContentCtaText = primary.viewAllCtaText;
  const cardContentCtaLink = primary.viewAllCtaLink.url;

  const slides = items.map((item, i) => {
    return (
      <div key={i} className="job-card">
        <div dangerouslySetInnerHTML={{ __html: item.cardContent.html }} className='label'></div>
        <Button href={item.cardCtaLink.url} className='learn-more'>
          <IconLinkArrow color="#c8102e"/>
          <span className="learn-more-txt">{item.cardCtaText}</span>
        </Button>
      </div>

    );
  });
  const sliderSettings = {
    infinite: false,
    autoplay: false,
    slidesToShow: 1,
    adaptiveHeight: false,
    arrows: false,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <section className='fwrd-job-cat-cards'>
      <div className="container">
        <div className='job-cat-cards__header' dangerouslySetInnerHTML={{ __html: cardContentHeader }}></div>
        <div className='job-cards'>
          <Slider {...sliderSettings}>{slides}</Slider>
        </div>
        <Button href={cardContentCtaLink} className="cta-button">
          {cardContentCtaText}
        </Button>
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageJobsCategoriesCardsFields on PrismicPageDataBodyJobsCategoriesCards {
  id, slice_type

  primary {
    heading { html }
    viewAllCtaText
    viewAllCtaLink { url }
  }

  items {
    cardContent { html }
    cardCtaText
    cardCtaLink { url }
    cardCtaLinkQuery
  }
}
`;
