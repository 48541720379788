import React from 'react';
import { graphql } from 'gatsby';
import './styles.scss';

function buildGridStyle (items) {
  const gridTemplateAreas = [
    [ '.', '.', '.' ],
    [ '.', '.', '.' ],
    [ '.', '.', '.' ]
  ];

  let colSum = 0;

  for (let i = 0; i < items.length; i++) {
    const columns = parseInt(items[i].columns);
    const row = Math.floor(colSum / 3);

    if (!gridTemplateAreas[row]) {
      console.warn(`Too many cells for FactsAndFigures: ${items.length}`);
      break;
    }

    for (let j = 0; j < columns; j++) {
      const col = (colSum % 3) + j;

      if (col > 2) {
        console.warn(`Cells may not span rows in FactsAndFigures: cell ${i}`);
        break;
      }

      gridTemplateAreas[row][col] = `box${i+1}`;
    }

    colSum += columns;
  }

  gridTemplateAreas[0].unshift('.', '.');
  gridTemplateAreas[1].unshift('.');
  gridTemplateAreas[1].push('.');
  gridTemplateAreas[2].push('.', '.');

  return {
    gridTemplateAreas: gridTemplateAreas.map(row => `'${row.join(' ')}'`).join("\n")
  };
}

function buildGridStyleStackedRight (items) {
  const gridTemplateAreas = [
    [ '.', '.', '.' ],
    [ '.', '.', '.' ],
    [ '.', '.', '.' ]
  ];

  const MAX_COLUMNS = gridTemplateAreas[0].length;

  let prevRowLen = 0;
  let rowLen = 0;
  let colSum = 0;

  for (let i = 0; i < items.length; i++) {
    const columns = parseInt(items[i].columns);
    const row = Math.floor(colSum / MAX_COLUMNS);

    if (!gridTemplateAreas[row]) {
      console.warn(`Too many cells for FactsAndFigures: ${items.length}`);
      break;
    }

    for (let j = 0; j < columns; j++) {
      const col = (colSum % MAX_COLUMNS) + j;

      if (col >= MAX_COLUMNS) {
        console.warn(`Cells may not span rows in FactsAndFigures: cell ${i}`);
        break;
      }

      gridTemplateAreas[row][col] = `box${i+1}`;
    }

    rowLen += columns;

    if (rowLen > prevRowLen) {
      prevRowLen = rowLen;
      rowLen = 0;
      colSum = (row + 1) * MAX_COLUMNS;
    } else {
      colSum += columns;
    }
  }

  for (let row of gridTemplateAreas) {
    row.reverse();

    while (row.length < 6) {
      row.unshift('.');
    }
  }

  return {
    gridTemplateAreas: gridTemplateAreas.map(row => `'${row.join(' ')}'`).join("\n")
  };
}

export function FactsAndFigures ({ primary, items, slice_label }) {
  const items_ = items.map((item, i) => {
    const colClassName = `col _${item.columns} ${item.feature ? 'feature' : ''}`;
    return (
      <div key={i} className={colClassName}>
        <span>{item.value}</span>
        <div dangerouslySetInnerHTML={{ __html: item.label.html }} className='label'></div>
      </div>
    );
  });

  let fnfHeaderContent = primary.heading.html;
  let className = 'fwrd-facts-and-figures';
  let headerClassName = 'fnf__header';

  let gridStyle = buildGridStyle(items);

  if (slice_label==='stacked_right') {
    fnfHeaderContent = primary.content.html;
    className += ' stacked_right';
    headerClassName += ' wysiwyg-slice-content';
    gridStyle = buildGridStyleStackedRight(items);
  }

  return (
    <section className={className}>
      <div className="fwrd-facts-and-figures__inner container --small">
        <div className={headerClassName} dangerouslySetInnerHTML={{ __html: fnfHeaderContent }}>
        </div>
        <div className='fnf__flex' style={gridStyle}>
          { items_ }
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageFactsAndFiguresFields on PrismicPageDataBodyFactsAndFigures {
  id, slice_type, slice_label

  primary {
    heading { html }
    content { html }
  }

  items {
    feature
    columns # "1", "2", or "3"
    value
    label { html }
  }
}
`;
