import React from 'react';
import Slider from "@maxmedia/react-slick";
import { graphql } from 'gatsby';
import './styles.scss';

export function TestimonialsSlider ({ primary, items }) {
  const testisHeader = primary.content.html;
  const testisQuoteImg = primary.backgroundImage.gatsbyImageData.images.fallback.src;
  const slides = items.map((item, i) => {
    const testiAttri = item.attribution.html;
    const testiImg = item.image.gatsbyImageData?.images?.fallback?.src;
    const testiQuote = item.quote.html;
    return (
      <div key={i} className="testi-slide">
        <div className='testi-slide__header'>
          <div className='img-slide-definer'>
            { testiImg ? <img src={testiImg} alt=""/> : null }
          </div>
          <div dangerouslySetInnerHTML={{ __html: testiAttri }} className='testi-attri'></div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: testiQuote }} className='quote'></div>
      </div>
    );
  });

  const sliderSettings = {
    dots: true,
    infinite: false,
    autoplay: false,
    slidesToShow: 1,
    adaptiveHeight: false,
    arrows: false,
    slidesToScroll: 1
  };

  return (
    <section className='fwrd-testis'>
      <div className='fwrd-testis__inner container --small'>
        <div className='testis-header'>
          <div className='img-definer'>
            <img src={testisQuoteImg} alt=""/>
          </div>
          <div dangerouslySetInnerHTML={{ __html: testisHeader }} className='testis-head'></div>
        </div>
        <div className='testi-slides'>
          <Slider {...sliderSettings}>{slides}</Slider>
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageTestimonialsSliderFields on PrismicPageDataBodyTestimonialsSlider {
  id, slice_type

  primary {
    backgroundImage { gatsbyImageData }
    content { html }
  }

  items {
    image { gatsbyImageData, alt }
    attribution { html }
    quote { html }
  }
}
`;
