import React, { useReducer } from 'react';
import { Chip, Paper } from '@mui/material';

export function ClickableChipsList ({ header, initialState }) {
  const [ chipList, setChipList ] = useReducer((state, { index, clicked }) => {
    state[index].clicked = !clicked;
    return Array.from(state);
  }, initialState);

  function handleClick ({ index, clicked }) {
    // setChipList({ index, clicked });
  }

  return (
    <Paper component="ul" className="fwrd-chiplist" elevation={0}>
      <div className="chiplist-header">
        { header }
      </div>
      <div className="chiplist-well">
        {
          chipList.map( (item, index) => {
            const { clicked } = item;
            return (
              <li key={ index }>
                <Chip
                  label={ item.keyword }
                  onClick={ handleClick.bind(null, { index, clicked }) }
                />
              </li>
            );
          })
        }
      </div>
    </Paper>
  );
}
