import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { JobListItem, LoadingSpinner } from '../../..';
import { formatDateStr } from '../../../../utils/format-date';
import { useMergePrismicPreviewData } from '../../../../utils/use-merge-prismic-preview-data';
import { useGeocode, haversineDistance, GEOCODE_ERROR } from './use-geocode';
import { useSearch, SEARCH_ERROR } from './use-search';

export function CareersJobsList ({
  pageStartNum = 1,
  perPage = 10,
  sortValue = 'date',
  filters = {},
  onLengthFound = () => {},
  search = null,
  queryLocation = null
}) {
  const onLengthFoundNextTick = (num) => setTimeout(() => onLengthFound(num));
  const [ searchResultsIdSet, searchResultsIdArr, doSearch ] = useSearch('job-careers_job', search);
  const [ geoLocation, doGeocode ] = useGeocode(queryLocation);

  useEffect(() => { doSearch(); }, [ doSearch ]);
  useEffect(() => { doGeocode(); }, [ doGeocode ]);

  const _data = useStaticQuery(graphql`
    query CareersJobsListQuery {
      allPrismicJob(
        filter: {data: {careersJob: {id: {glob: "*"}}}}
      ) {
        nodes {
          _previewable

          prismicId

          data {
            careersDepartment
            careersExperience
            careersJobType
            careersSchedule
            careersJob {
              Id
              Title
              ShortDescriptionStr
              ExternalDescriptionStr
              ExternalPostedStartDate
              PrimaryLocation
              geoLocation { lat, lng }
            }
          }
        }
      }
    }
  `);

  const data = useMergePrismicPreviewData(_data);

  if ((search && !searchResultsIdSet) || (queryLocation && !geoLocation)) {
    onLengthFoundNextTick(0);
    return ( <div className="job-list"><LoadingSpinner /></div> );
  }

  const items = data.allPrismicJob.nodes.filter((node) => {
    for (let key in filters) {
      const filter = filters[key];

      if (filter?.size && !filter.has(node.data[key])) return false;
    }

    return true;
  }).filter((node) => {
    if (!search) return true;
    if (!searchResultsIdSet) return false;
    if (searchResultsIdSet === SEARCH_ERROR) return true;

    return searchResultsIdSet.has(node.prismicId);
  }).filter((node) => {
    if (!queryLocation) return true;
    if (!geoLocation) return false;
    if (geoLocation === GEOCODE_ERROR) return true;

    if (node.data.careersJob.geoLocation) {
      node.geoLocationDistance = haversineDistance(geoLocation, node.data.careersJob.geoLocation);
    } else {
      node.geoLocationDistance = Number.MAX_SAFE_INTEGER;
    }

    return node.geoLocationDistance < 1000; // within 1000 km
  }).sort((a, b) => {
    if (search && sortValue === 'relevance') {
      return searchResultsIdArr.indexOf(b.prismicId) - searchResultsIdArr.indexOf(a.prismicId);
    }

    if (geoLocation && sortValue === 'distance') {
      return a.geoLocationDistance - b.geoLocationDistance;
    }

    if (sortValue === 'date' || sortValue === 'relevance' || sortValue === 'distance') {
      return Date.parse(a.data.careersJob.date) - Date.parse(b.data.careersJob.date);
    }

    console.warn(`Invalid sortValue: ${sortValue}`);
    return 0;
  });

  onLengthFoundNextTick(items.length);

  const itemsRendered = items
    .slice(pageStartNum - 1, pageStartNum - 1 + perPage)
    .map((node, index) => {
      const summaryData = [
        { label: 'Schedule', value: node.data.careersSchedule },
        { label: 'Job Type', value: node.data.careersJobType },
        { label: 'Experience', value: node.data.careersExperience },
        { label: 'Department', value: node.data.careersDepartment }
      ];

      const { careersJob } = node.data;

      const applyUrl = `https://eguq.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX/job/${careersJob.Id}/apply/email`;

      return (
        <JobListItem
          key={index}
          listingOrigin="careers"
          id={careersJob.Id}
          title={careersJob.Title}
          employer="Forward Air"
          city={careersJob.PrimaryLocation}
          postedAt={formatDateStr(careersJob.ExternalPostedStartDate)}
          fullDescription={careersJob.ExternalDescriptionStr}
          summaryData={summaryData}
          applyUrl={applyUrl}
        />
      );
    });

  return ( <div className="job-list">{itemsRendered}</div> );
}
