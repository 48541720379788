import React from 'react';
import './styles.scss';

export function TrackingHeader ({ headerContent }) {
  return (
    <div className="fwrd-tracking-header">
      <div
        className="tracking-info"
        dangerouslySetInnerHTML={{ __html: headerContent }}
      ></div>
    </div>
  );
}
