import React, { useState } from 'react';
import { ToggleButtonGroup } from '@mui/material';
import './styles.scss';

export function ToggleButtons ({
  className,
  initialValue = null,
  onChange = ()=>{},
  children,
  ...props
}) {
  const [ selected, setSelected ] = useState(initialValue);

  const handleChange = (newEvent, nextSelected) => {
    setSelected(nextSelected);

    onChange(newEvent, nextSelected);
  };

  return (
    <ToggleButtonGroup
      exclusive
      value = { selected }
      onChange={handleChange}

      className={ `fwrd-toggle-group ${className ?? ''}` }
      {...props}
    >

      {children}

    </ToggleButtonGroup>

  );
}

