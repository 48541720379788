import React, { useCallback } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import './styles.scss';

export function AutofillDropdown ({
  label,
  className,
  options,
  props
}) {
  const renderOption = useCallback((optionProps, option) => {
    return (
      <li {...optionProps}>
        <div className="list-item">
          {option.label}
          <div className="list-item-assistive-text">
            {option.sublabel}
          </div>
        </div>
      </li>
    );
  }, []);

  const renderInput = useCallback((params) => {
    return (
      <TextField {...params} label={label} />
    );
  }, [ label ]);

  return (
    <div className={`fwrd-autocomplete ${className ?? ''}`}>
      <Autocomplete
        disablePortal
        forcePopupIcon={false}
        options={options}
        renderOption={renderOption}
        getOptionLabel={(option) => option.label}
        renderInput={renderInput}
        {...props}
      />
    </div>
  );
}
