import React, { useReducer } from 'react';
import { Chip, Paper } from '@mui/material';
import { CloseIcon } from '../icons';

export function DeletableChipsList ({ initialState }) {
  const [ chipList, updateChipList ] = useReducer((state, index) => {
    const newState = Array.from(state);
    newState.splice(index, 1);
    return newState;
  }, initialState);

  function handleDelete (index) {
    updateChipList(index);
  }

  return (
    <Paper component="ul" className="fwrd-chiplist" elevation={0}>
      {
        chipList.map( (data, index) => {
          return (
            <li key={ index }>
              <Chip
                icon={ null }
                deleteIcon={<CloseIcon />}
                label={ data.label }
                onDelete={ handleDelete.bind(null, index) }
              />
            </li>
          );
        })
      }
    </Paper>
  );
}
