import React from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import { IconLeftArrowSvgIcon, IconRightArrowSvgIcon } from '../icons';

export function ArrowPagination ({ className, ...props }) {
  const renderItem = (item) => {
    if (item.type ==="previous") {
      return <PaginationItem {...item} type="page" page={(<IconLeftArrowSvgIcon/>)} />;
    } else if (item.type === "next") {
      return <PaginationItem {...item} type="page" page={(<IconRightArrowSvgIcon/>)} />;
    }

    return null;
  };

  return (
    <div>
      <Pagination
        className={`fwrd-arrows-pagination ${className ?? ''}`}
        {...props}
        shape="rounded"
        size='small'
        renderItem={renderItem}
      />
    </div>
  );
}
