import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Img } from '@maxmedia/mxm-react-lib';
import './styles.scss';

export function columnsContentPrismicClasses ({
  backgroundColor,
  diagonalColor,
  diagonalPosition,
  raisedContentBox
}) {
  const bgColor = `bg-${backgroundColor}`;
  const diagColor = `diag-color-${diagonalColor}`;
  const diagPosition = `diag-${diagonalPosition}`;
  const raisedContent = raisedContentBox ? 'raised-content' : "";

  return `${bgColor} ${diagColor} ${diagPosition} ${raisedContent}`;
}

export function ColumnsContent ({
  primary,
  items
}) {
  // const { raisedContentBox } = primary;
  const [ imagePresence, setImagePresence ] = useState(false);

  const sliceContentBlockRendered = () => {
    if (!items?.length) return null;

    return items.map( (item, i) => {
      const { content, image } = item;

      if (image.gatsbyImageData) {
        return (
          <div
            key={i}
            className="slice-col image-col"
          >
            <Img image={image} alt={image.alt ?? ''} />
          </div>
        );
      }

      return (
        <div
          key={i}
          className="slice-col content-col wysiwyg-slice-content"
          dangerouslySetInnerHTML={{ __html: content.html }}
        />
      );
    });
  };

  const prismicClasses = columnsContentPrismicClasses(primary);

  return (
    <section className={`fwrd-slice-columns-content module-padding ${prismicClasses}`}>
      <div className="slice-wrapper container --small">
        <div className="content-wrapper">
          { sliceContentBlockRendered() }
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageColumnsContentFields on PrismicPageDataBodyColumnsContent {
  id, slice_type

  primary {
    backgroundColor
    diagonalPosition
    diagonalColor
    raisedContentBox
  }

  items {
    content { html }
    image { gatsbyImageData, alt }
  }
}
`;
