import React, { useState, useCallback } from 'react';
import { TextInput } from '../form-inputs';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DesktopDatePicker } from '@mui/lab';
import './styles.scss';

export function FwrdDatePicker ({
  label,
  className,
  onChange = () => {},
  ...props
}) {
  const [ value, setValue ] = useState(null);

  const handleChange = useCallback((newValue) => {
    setValue(newValue);
    onChange(newValue);
  }, [ onChange ]);

  const renderInput = useCallback(({ inputRef, inputProps, InputProps }) => {
    return (
      <TextInput
        ref={inputRef}
        {...inputProps}
        {...InputProps}
        label={label}>
        {InputProps?.endAdornment}
      </TextInput>
    );
  }, [ label ]);

  return (
    <div className={`fwrd-date-picker-input ${className ?? ''}`}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          //this weird way of labeling a class is needed with this particular MUI component. it is a known issue to MUI.
          PaperProps={{ className: 'fwrd-date-picker' }}
          value={value}
          onChange={handleChange}
          renderInput={renderInput}
          {...props}
        />
      </LocalizationProvider>
    </div>
  );
}
