import React, { useState, useMemo, useCallback } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { ActionBar, Button, ContentCard, CtaCard, SuccessChipsList, CollectionFilters } from '../index';
import { SignupForm } from '../slice-zone/slices/signup-form';
import defaultPhoto from '../../images/cta-image.jpg';

export function ResourcesBody ({
  data,
  allResourceArticles,
  featuredResourceArticles,
  mostRecentResourceArticles
}) {
  const isLarge = useMediaQuery('(min-width:1600px)');
  const isDesktop = useMediaQuery('(min-width:1280px) and (max-width:1599px)');
  const isMobile = useMediaQuery('(max-width:599px)');
  const [ featTag, setFeatTag ] = useState('featured');
  const [ relatedTerms, setRelatedTerms ] = useState([]);
  const [ showAdditionalArticles, setShowAdditionalArticles ] = useState(0);

  const filters = [
    { label: 'Featured', value: 'featured' },
    { label: 'Most Recent', value: 'most-recent' },
    { label: 'All', value: 'all' }
  ];

  const articlesPerSection = useMemo(() => {
    if (isLarge) return 8;
    if (isMobile) return 4;
    return 6;
  }, [ isLarge, isMobile ]);

  const section1Size = useMemo(() => {
    if (isLarge) return (articlesPerSection ?? 0) + 2;
    if (isDesktop) return (articlesPerSection ?? 0) + 1;
    return articlesPerSection ?? 0;
  }, [ articlesPerSection, isLarge, isDesktop ]);

  const renderArticles = (arr) => {
    return (
      arr?.map( (article, index) => (
        <div key={index} className='logo-image'>
          <ContentCard
            headline={ article.data.title.text }
            category={ article.data.category }
            image={ article.data.image }
            readTime={ article.data.readTime }
            bodyCopy={ article.data.description.text }
            dateStamp={ article.data.publishDate }
            featured={ article.tags.includes('featured') ? true : false }
            url={ article.url }
          >
            <ActionBar
              dateStamp={ article.data.publishDate }
              readTime={ article.data.readTime }
            />
          </ContentCard>
        </div>
      ))
    );
  };

  const featArticles = useMemo(() => {
    if (featTag === 'featured') {
      return featuredResourceArticles.slice(0, articlesPerSection);
    }

    if (featTag === 'most-recent') {
      return mostRecentResourceArticles.slice(0, articlesPerSection);
    }

    if (featTag === 'all') {
      return allResourceArticles.nodes.slice(0, articlesPerSection);
    }

    console.warn(`Invalid featTag: ${featTag}`);
  },
  [
    featTag,
    articlesPerSection,
    featuredResourceArticles,
    mostRecentResourceArticles,
    allResourceArticles
  ]);

  const clickableChipsArr = useMemo(() => {
    return allResourceArticles.keywords.map((item) => {
      return { label: item };
    });
  }, [ allResourceArticles ]);

  const filteredArticles = useMemo(() => {
    const terms = new Set(relatedTerms.reduce((memo, t) => {
      if (t.clicked) memo.push(t.label);
      return memo;
    }, []));

    let theArticles = allResourceArticles.nodes;

    if (terms.size) {
      theArticles = allResourceArticles.nodes.filter((article) => {
        for (let { keyword } of (article.data.keywords ?? [])) {
          if (terms.has(keyword)) return true;
        }

        return false;
      });
    }

    return [
      theArticles.slice(0, section1Size),
      theArticles.slice(section1Size, section1Size + articlesPerSection + showAdditionalArticles)
    ];
  },
  [
    relatedTerms,
    articlesPerSection,
    section1Size,
    showAdditionalArticles,
    allResourceArticles
  ]);

  const signupFormPrimary = useMemo(() => {
    return {
      content: data.signupFormContent,
      image: data.signupFormImage,
      actionUrl: data.signupFormActionUrl,
      ctaText: data.signupFormCtaText
    };
  }, [ data ]);

  const hasMore = useMemo(() => {
    if (!filteredArticles) return false;
    return filteredArticles[0].length + filteredArticles[1].length
      < allResourceArticles.nodes.length;
  }, [ filteredArticles, allResourceArticles ]);

  const handleLoadMore = useCallback(() => {
    setShowAdditionalArticles(showAdditionalArticles + articlesPerSection);
  }, [ showAdditionalArticles, articlesPerSection ]);

  return (
    <>
      <div className="fwrd-resources-container --dark">
        <div className="container">
          <CollectionFilters filters={filters} onChange={setFeatTag} />
          <div className="articles-well first-section">
            { renderArticles(featArticles) }
          </div>

          <div className="related-keywords">
            <SuccessChipsList
              header="Related Keywords"
              initialState={ clickableChipsArr }
              onChange={ setRelatedTerms }
            />
          </div>
          <div className="articles-well">
            <div className='logo-image cta-block'>
              <CtaCard title="Drivers" ctaTitle="Learn More" ctaTarget="/" ctaImage={ defaultPhoto } overlay />
            </div>
            { renderArticles(filteredArticles[0]) }
          </div>
        </div>
      </div>
      <div className="signup-form">
        <SignupForm primary={signupFormPrimary} slice_label={data.signupFormType} />
      </div>
      <div className="fwrd-resources-container --dark">
        <div className="container">
          <div className="articles-well closing-section">
            { renderArticles(filteredArticles[1]) }
          </div>

          <div className="articles-well no-grid">
            <div className="pagination-bar">
              <Button
                variant="text"
                className={`load-more-btn visible-${hasMore}`}
                disableRipple
                onClick={ handleLoadMore }
              >
                Load More
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
