import React, { useMemo, useState } from "react";
import Slider from "@maxmedia/react-slick";
import { ChevronLeftIcon, ChevronRightIcon } from '../../../icons';
import { Img } from '@maxmedia/mxm-react-lib';
import { graphql } from 'gatsby';
import './styles.scss';

function NextArrow (props) {
  const { onClick: handleClick } = props;
  return (
    <div
      className='slick-arrow slick-prev'
      onClick={handleClick}
      onKeyDown={handleClick}
      role='button'
      tabIndex='0'>
      <ChevronLeftIcon />
    </div>
  );
}

function PrevArrow (props) {
  const { onClick: handleClick } = props;
  return (
    <div
      className='slick-arrow slick-next'
      onClick={handleClick}
      onKeyDown={handleClick}
      role='button'
      tabIndex='0'>
      <ChevronRightIcon />
    </div>
  );
}

export function ServiceMapOverview ({ items }) {
  const slides = items.map((item, i) => {
    const data = [];

    for (let l = 1; l <= 6; l++) {
      data.push({
        label: item[`datumLabel${l}`],
        value: item[`datumValue${l}`]
      });
    }
    const datums = data.map((point, d) => {
      return (
        <div key={d} className='datum'>
          <h3>{point.label}</h3>
          <span>{point.value}</span>
        </div>
      );
    });

    const serviceHtmlContent = item.content.html;

    const serviceMap = item.map;

    const serviceMapTitle = item.mapTitle;

    const serviceMapLink = item.mapLink.url;
    return (
      <div key={i} className="slide">
        <div className='slide__content-map'>
          <div dangerouslySetInnerHTML={{ __html: serviceHtmlContent }} className='slide__content wysiwyg-slice-content'></div>
          <div className='slide__map'>
            <h2>{serviceMapTitle}</h2>
            <a href={serviceMapLink} alt="" className='map'>
              <Img image={serviceMap} alt="" objectfit="cover"/>
            </a>

          </div>
        </div>
        <div className='slide__datum'>
          {datums}
        </div>
      </div>
    );
  });
  const sliderSettings = {
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    adaptiveHeight: false,
    nextArrow: <PrevArrow/>,
    prevArrow: <NextArrow/>,
    slidesToScroll: 1
  };

  return (
    <section className='fwrd-service-map-overview'>
      <Slider {...sliderSettings} className="service-slides container">{slides}</Slider>
    </section>
  );
}

export const query = graphql`
fragment pageServiceMapOverviewFields on PrismicPageDataBodyServiceMapOverview {
  id, slice_type

  items {
    map { gatsbyImageData }
    content { html }
    mapTitle
    mapLink { url }
    datumValue1, datumLabel1
    datumValue2, datumLabel2
    datumValue3, datumLabel3
    datumValue4, datumLabel4
    datumValue5, datumLabel5
    datumValue6, datumLabel6
  }
}
`;
