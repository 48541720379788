import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Button, ContentCard, ActionBar } from '../../../../components';
import { IconLinkArrow } from '../../../icons';
import { useMergePrismicPreviewData } from '../../../../utils/use-merge-prismic-preview-data';
import './styles.scss';

export function RelatedArticles ({ primary, items }) {
  const {
    ctaLink,
    ctaText,
    readMoreCtaText,
    showCardImages,
    contentLeft,
    contentRight,
    cardStyleFull
  } = primary;
  const _globalData = useStaticQuery(graphql`
    query DefaultsGlobalsQuery {
      prismicGlobalOptions {
        _previewable
         data {
          defaultArticleImage {
            alt
            gatsbyImageData
          }
        }
      }
    }
  `);

  const globalData = useMergePrismicPreviewData(_globalData);

  let headingContentRendered = null;
  if (contentLeft.html || contentRight.html ) {
    const contentLeftHtml = (
      <div className={`content-header ${contentLeft.html && contentRight.html ? 'lrg-header' : ''}`} dangerouslySetInnerHTML={{ __html: contentLeft.html }}/>
    );
    const contentRightHtml = (
      <div className='content-body' dangerouslySetInnerHTML={{ __html: contentRight.html }}/>
    );

    headingContentRendered = (
      <div className={`content-head ${contentLeft.html && contentRight.html ? 'dual-content' : ''}`}>
        { contentLeft.html ? contentLeftHtml : null }
        { contentRight.html ? contentRightHtml : null }
      </div>
    );
  }
  const articles = items.map(({ article }, i) => {
    // Fix for preview mode with unpublished articles
    if (!article.document) return null;

    const { category, title, description } = article.document.data;
    let { image } = article.document.data;
    let imageClassName = '';

    if (!image.gatsbyImageData) {
      image = globalData.prismicGlobalOptions.data.defaultArticleImage;
      imageClassName = 'default-image';
    }

    const buttonArrow = <IconLinkArrow color="#c8102e" />;

    if (cardStyleFull === true ) {
      return (
        <div className="article full-card-article" key={i}>
          <ContentCard
            featured={article.document.tags.includes('featured')}
            image={image}
            imageClassName={imageClassName}
            category={category}
            headline={title.text}
            bodyCopy={description.text}
            className="card-style-full"
            url={ article.document.url }
            elevation={0}
          >
            <ActionBar
              dateStamp="2022-11-20"
              readTime="7"
            />
          </ContentCard>
        </div>
      );
    }

    return (
      <div className="article" key={i}>
        <ContentCard
          featured={article.document.tags.includes('featured')}
          image={showCardImages ? image : null}
          imageClassName={imageClassName}
          category={category}
          headline={title.text}
          bodyCopy={description.text}
          buttonRow={
            <>
              <Button
                className="card-button no-padding"
                variant="text"
                startIcon={buttonArrow}
                to={article.document.url}
              >
                {readMoreCtaText}
              </Button>
            </>
          }
          className="no-padding mob-hide-image"
          url={ article.document.url }
          elevation={0}
        >
        </ContentCard>
      </div>
    );
  });

  let ctaWrapper = null;

  if (ctaText && ctaLink) {
    ctaWrapper = (
      <div className="cta-wrapper">
        <Button className="red" variant="contained" to={ctaLink?.url}>
          { ctaText }
        </Button>
      </div>
    );
  }

  return (
    <section className="fwrd-related-articles">
      <div className="container --small-medium">
        {headingContentRendered}
        <div className={`articles-wrapper ${articles.length > 3 ? 'extended-grid' : ''}`}>
          {articles}
        </div>
        {ctaWrapper}
      </div>
    </section>
  );
}

export const query = graphql`
fragment homepageRelatedArticlesFields on PrismicHomepageDataBodyRelatedArticles {
  id, slice_type

  primary {
    ctaLink { url }
    ctaText
    readMoreCtaText
    contentLeft { html }
    contentRight { html }
    cardStyleFull
    showCardImages
  }

  items {
    article {
      document {
        ... on PrismicArticles {
          _previewable
          url
          tags
          data {
            category
            title { text }
            description { text }
            image { gatsbyImageData, alt }
          }
        }
      }
    }
  }

}

fragment pageRelatedArticlesFields on PrismicPageDataBodyRelatedArticles {
  id, slice_type
  primary {
    contentLeft { html }
    contentRight { html }
    cardStyleFull
    ctaLink { url }
    ctaText
    readMoreCtaText
  }
  items {
    article {
      document {
        ... on PrismicArticles {
          _previewable
          url
          tags
          data {
            category
            title { text }
            description { text }
            image { gatsbyImageData, alt }
          }
        }
      }
    }
  }
}

fragment the404PageRelatedArticlesFields on Prismic404PageDataBodyRelatedArticles {
  id, slice_type

  primary {
    ctaLink { url }
    ctaText
    readMoreCtaText
    contentLeft { html }
    contentRight { html }
    cardStyleFull
    showCardImages
  }

  items {
    article {
      document {
        ... on PrismicArticles {
          _previewable
          url
          tags
          data {
            category
            title { text }
            description { text }
            image { gatsbyImageData, alt }
          }
        }
      }
    }
  }
}

fragment trackingPageRelatedArticlesFields on PrismicTrackingPageDataBodyRelatedArticles {
  id, slice_type

  primary {
    ctaLink { url }
    ctaText
    readMoreCtaText
    contentLeft { html }
    contentRight { html }
    cardStyleFull
    showCardImages
  }

  items {
    article {
      document {
        ... on PrismicArticles {
          _previewable
          url
          tags
          data {
            category
            title { text }
            description { text }
            image { gatsbyImageData, alt }
          }
        }
      }
    }
  }
}

fragment loginPageRelatedArticlesFields on PrismicLoginPageDataBodyRelatedArticles {
  id, slice_type

  primary {
    ctaLink { url }
    ctaText
    readMoreCtaText
    contentLeft { html }
    contentRight { html }
    cardStyleFull
    showCardImages
  }

  items {
    article {
      document {
        ... on PrismicArticles {
          _previewable
          url
          tags
          data {
            category
            title { text }
            description { text }
            image { gatsbyImageData, alt }
          }
        }
      }
    }
  }
}

fragment searchPageRelatedArticlesFields on PrismicSearchPageDataBodyRelatedArticles {
  id, slice_type

  primary {
    ctaLink { url }
    ctaText
    readMoreCtaText
    contentLeft { html }
    contentRight { html }
    cardStyleFull
    showCardImages
  }

  items {
    article {
      document {
        ... on PrismicArticles {
          _previewable
          url
          tags
          data {
            category
            title { text }
            description { text }
            image { gatsbyImageData, alt }
          }
        }
      }
    }
  }
}
`;
