import React from 'react';
import { BasicModal, ContentAccordion } from '..';
import { StatusBar } from './status-bar';
import { TrackingActivity, TrackingActivityStart } from '../icons';

export function TrackingModal ({ handleCloseModal, resultDetails, statuses }) {
  const handleClose = () => {
    setTimeout(handleCloseModal(false), 500);
  };

  const shippingStatus = statuses.find(
    x => x.statusCode === resultDetails.CurrentStatusCode
  );

  return (
    <BasicModal
      heading="Shipment Details"
      onClose={handleClose}
      isCrit={true}
      className="fwrd-shipment-details-modal"
    >
      <div className="shipment-details-wrapper">
        <div className="shipment-details">
          <div className="delivery-details">
            <div className="delivery-date">
              <div className="copy">Expected Delivery</div>
              <div className="header --small headline">{resultDetails.EstimatedAvailableDate}</div>
            </div>
          </div>
          <div className="shipment-overview">
            <div className="shipment-number">
              <div className="copy --small">Shipment Number</div>
              <div className="header headline">{resultDetails.AirbillNumber}</div>
            </div>
            <div className="shipment-status">
              <div className="copy --small">Status</div>
              <div className="header modifier --small text-uppercase" style={
                {
                  color: shippingStatus.statusColor
                }
              }
              >
                {shippingStatus.generalizedDescription}
              </div>
            </div>
          </div>
        </div>
        <div className="shipment-progress">
          <div className="copy">Shipment Progress:</div>
          <div className="progress-message">This shipment is being {resultDetails.CurrentStatusDescription.toLowerCase()}</div>
          <StatusBar color="#c70000" statuses={statuses} resultDetails={resultDetails} />
        </div>
      </div>
      <ContentAccordion
        id="fwrd-shipment-details-accordion"
        heading={<h5>Shipment</h5>}
        initialExpanded={ true }
      >
        <section className="fwrd-accordion-details-section">
          <div className="shipment-number">
            <div className="copy --small">Shipment Number</div>
            <div className="header modifier text-black">{resultDetails.AirbillNumber}</div>
          </div>
          <div className="weight">
            <div className="copy --small">Weight</div>
            <div className="copy">
              {resultDetails.Weight}
              {
                resultDetails.Weight === 1
                  ? ' lb'
                  : ' lbs'
              }
            </div>
          </div>
          <div className="shipment-destination">
            <div className="copy --small">Destination</div>
            <div className="header modifier text-black">{resultDetails.Origin} - {resultDetails.Destination}</div>
          </div>
          <div className="weight">
            <div className="copy --small">Amount</div>
            <div className="copy">
              {resultDetails.Pieces}
              {
                resultDetails.Pieces === 1
                  ? ' Item'
                  : ' Items'
              }
            </div>
          </div>
        </section>
      </ContentAccordion>
      <ContentAccordion
        id="fwrd-activity-details-accordion"
        heading={<h5>Activity</h5>}
      >
        <section className="fwrd-accordion-activity-section">
          {
            [ ...resultDetails.Statuses ].reverse().map((activity, i) => {
              let icon = <TrackingActivity />;
              let cls = 'listing-col';
              if (i === 0) {
                icon = <TrackingActivityStart />;
                cls += ' origin-location';
              }

              return (
                <div className={cls} key={i}>
                  <div className="icon-wrapper">
                    {icon}
                  </div>
                  <div className="tracking-details-wrapper">
                    <div className="copy --small">{activity.StatusDescription}</div>
                    <div className="copy text-black">Status: {activity.AirportCode}</div>
                    <div className="copy text-black">Date: {activity.RecordDate}</div>
                    <div className="copy text-black">Weight: {activity.ActualWeight}
                      {
                        activity.ActualWeight === 1
                          ? ' lb'
                          : ' lbs'
                      }
                    </div>
                    <div className="copy  text-black">Signed By: {activity.RecordedBy}</div>
                  </div>
                </div>
              );
            })
          }
          <div className="listing-col">
            <div className={"icon-wrapper " + (!shippingStatus.nextStatusText ? "last":"")}>
              <TrackingActivity />
            </div>
            <div className="tracking-details-wrapper">
              <div className="copy --small">{shippingStatus.generalizedDescription}</div>
              <div className="copy text-black">Status: {resultDetails.LastHandled}</div>
              <div className="copy text-black">Date: {resultDetails.RecordDate}</div>
              <div className="copy text-black">Weight: {resultDetails.Weight}
                {
                  resultDetails.Weight === 1
                    ? ' lb'
                    : ' lbs'
                }
              </div>
              <div className="copy text-black">Signed By: {resultDetails.RecordedBy}</div>
            </div>
          </div>
          {
            shippingStatus.nextStatusText &&
              <div className="listing-col next-status">
                <div className="icon-wrapper last">
                  <TrackingActivity />
                </div>
                <div className="tracking-details-wrapper">
                  <div className="copy text-grey-4">{shippingStatus.nextStatusText}</div>
                </div>
              </div>
          }
        </section>
      </ContentAccordion>
    </BasicModal>
  );
}
