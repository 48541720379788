import React, { useEffect, useState, useRef } from 'react';
import { Button, MenuItem, MenuList, Paper, Popper, Stack, ClickAwayListener, Grow } from '@mui/material';
import { NavArrowDown } from '../icons';
import './styles.scss';

export function MenuDropdown ({ buttonText = '', listItems = [] }) {
  const [ openMenu, setOpen ] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown (e) {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpen(false);
    } else if (e.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(openMenu);
  useEffect(() => {
    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openMenu;
  }, [ openMenu ]);

  return (
    <Stack direction="row" spacing={2}>
      <Button
        id="composition-button"
        ref={anchorRef}
        aria-controls={openMenu ? 'composition-menu' : undefined}
        aria-expanded={openMenu ? 'true' : undefined}
        aria-haspopup="true"
        disableRipple
        className="fwrd-dropdown-menu-button"
        onClick={ handleToggle }
      >
        <span className="button-text">{buttonText}</span>
        <span className="arrow-down"><NavArrowDown /></span>
      </Button>
      <Popper
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {
          ({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={
                {
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom'
                }
              }
            >
              <Paper className='fwrd-menu-dropdown-popover' sx={{ minWidth: anchorRef.current?.offsetWidth }}>
                <ClickAwayListener onClickAway={ handleClose }>
                  <MenuList
                    autoFocusItem={openMenu}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={ handleListKeyDown }
                  >
                    {
                      listItems.map((li, i) => {
                        const {
                          assistiveText,
                          text
                        } = li;

                        const handleClick = (ev) => {
                          setOpen(false);

                          if (li.handleClick) li.handleClick(ev);
                        };

                        return (
                          <MenuItem
                            key={`menu-dropdown-${i}`}
                            onClick={ (ev) => { handleClick(ev); }}
                          >
                            <span className="list-item-label">{ text }</span>
                            {
                              assistiveText ? (
                                <span className="list-item-assistive-text">{ assistiveText }</span>
                              ) : null
                            }
                          </MenuItem>
                        );
                      })
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )
        }
      </Popper>
    </Stack>
  );
}
