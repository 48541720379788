import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Img, LinkWrapper } from '@maxmedia/mxm-react-lib';
import { FeaturedFlag } from './featured-flag';
import { formatDateStr } from '../../utils/format-date';
import './styles.scss';

export * from './cta-card';
export * from './action-bar';

export function ContentCard ({
  image,
  imageClassName,
  category,
  headline,
  subheadline,
  bodyCopy,
  dateStamp,
  className,
  buttonRow,
  featured,
  elevation,
  children,
  url
}) {
  const categoryRendered = () => {
    if (!category) return null;

    let categoryClassName = [ 'card-category' ];
    if (featured) categoryClassName.push('--with-featured');
    if (image) categoryClassName.push('--with-media');

    return (
      <h4 className={categoryClassName.join(' ')}>
        <span>{ category }</span>
      </h4>
    );
  };

  const featuredRendered = () => {
    if (!featured) return null;
    return ( <FeaturedFlag className={image ? '--with-media' : ''} /> );
  };

  const cardImageRendered = () => {
    if (!image) return null;

    if (image.gatsbyImageData) {
      return (
        <Img image={image} alt={image.alt ?? ''} className={imageClassName} />
      );
    }

    return (
      <div className="card-media">
        <img src={ image } alt={ headline } />
      </div>
    );
  };

  return (
    <LinkWrapper url={ url } className='fwrd-content-card-link'>
      <Card className={ `fwrd-content-card ${className}` } elevation={elevation} square={true}>
        {featuredRendered()}
        { cardImageRendered() }

        <CardContent className="row-content">
          { categoryRendered() }
          { headline ? <h3 className="card-header">{ headline }</h3> : null }
          { subheadline ? <p className="card-date">{ subheadline }</p> : null }
          { dateStamp ? <p className="card-date">{ formatDateStr(dateStamp) }</p> : null }
          { bodyCopy ? <p className="card-copy">{ bodyCopy }</p> : null }
          { buttonRow }
        </CardContent>

        { children }
      </Card>
    </LinkWrapper>
  );
}
