const IMAGE_FOCUS_OBJECT_POSITION_MAP = {
  'Center': 'center',
  'Left': 'center',
  'Right': 'right'
};

const DEFAULT_OBJECT_POSITION = 'center';

export function imageFocusToObjectPosition (imageFocus, fallback = DEFAULT_OBJECT_POSITION) {
  return IMAGE_FOCUS_OBJECT_POSITION_MAP[imageFocus] ?? fallback;
}
