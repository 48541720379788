export function formatDateStr (dateStr) {
  if (!dateStr) return null;

  const date = new Date(Date.parse(dateStr));

  if (isNaN(date)) {
    console.warn('Invalid date format! Operation failed in: formatDateStr()');
    return dateStr;
  }

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  const formattedDateStr = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDateStr;
}
