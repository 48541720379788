import React from 'react';
import { graphql } from 'gatsby';
import { Img, LinkWrapper } from '@maxmedia/mxm-react-lib';
import { Button, InputPassword, InputCheckbox } from '../../../../components';
import './styles.scss';
import { TextInput } from '../../../form-inputs';

export function LoginForm ({ primary }) {
  return (
    <section className='fwrd-login-form'>
      <div className='login-form__inner container --small'>
        <Img image={primary.image1} alt={primary.image1.alt} className="login-image"/>
        <div className='login-content'>
          <form className='login-content_inner'>
            <div className='login-header wysiwyg-slice-content' dangerouslySetInnerHTML={{ __html: primary.contentBefore.html }} />

            <div className='account-info-container'>
              <TextInput
                className='user-id'
                label='User ID'
                type='email'
                required
              />
              <InputPassword className='user-password'/>
            </div>

            <div className='login-help'>
              <InputCheckbox className='rememberMe-checkBox' label='Remember Me' checked={primary.rememberMe}/>
              <LinkWrapper url={primary.forgotPasswordLink.url} className="forgot-password-link"><span>Forgot Password?</span></LinkWrapper>
            </div>

            <Button className='login-button' variant="contained">Login</Button>
            <div className='register-content wysiwyg-slice-content' dangerouslySetInnerHTML={{ __html: primary.contentAfter.html }} />
          </form>
        </div>
      </div>

    </section>
  );
}

export const query = graphql`
fragment loginPageLoginFormFields on PrismicLoginPageDataBodyLoginForm {
  id, slice_type

  primary {
    image1 { gatsbyImageData, alt }
    contentBefore { html }
    contentAfter { html }
    rememberMe
    forgotPasswordLink { url }
  }
}
`;
