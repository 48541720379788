import React, { useMemo, useCallback, useReducer } from 'react';
import { useQueryParam, ArrayParam, withDefault } from 'use-query-params';
import { BasicModal, Button, ContentAccordion, InputCheckbox } from '../../../';

export function JobFiltersModal ({
  filters,
  selectedFilters,
  openGroup,
  onClose = () => {}
}) {
  const [ queryFilters, setQueryFilters ] = useQueryParam('f', withDefault(ArrayParam, []));

  const initialSelections = useCallback(() => {
    const result = {};

    for (let key in filters) {
      if (selectedFilters[key]) {
        result[key] = new Set(selectedFilters[key]);
      } else {
        result[key] = new Set(filters[key].config.options);
      }
    }

    return result;
  }, [ filters, selectedFilters ]);

  const [ selections, setSelections ] = useReducer((prevState, action) => {
    if (action.clear) {
      const newState = {};

      for (let key in filters) {
        newState[key] = new Set(filters[key].config.options);
      }

      return newState;
    }

    if (action.toggle) {
      const { key, value } = action.toggle;

      let group = prevState[key];

      if (group.size === filters[key].config.options.length) {
        group = new Set([ value ]);
      } else if (group.has(value)) {
        group.delete(value);
      } else {
        group.add(value);
      }

      if (!group.size) {
        group = new Set(filters[key].config.options);
      }

      return { ...prevState, [key]: group };
    }

    if (action.set) {
      const { key, value, checked } = action.set;

      let group = prevState[key];

      if (group.size === filters[key].config.options.length) {
        group = new Set([ value ]);
      } else if (checked) {
        group.add(value);
      } else {
        group.delete(value);
      }

      if (!group.size) {
        group = new Set(filters[key].config.options);
      }

      return { ...prevState, [key]: group };
    }

    console.error(`action: ${JSON.stringify(action)}`);
    throw new Error(`Invalid action`);
  }, initialSelections());

  const accordions = Array.from(Object.keys(filters)).map((key, index) => {
    const { label, options } = filters[key].config;

    const checkboxes = options.map((value, filterIndex) => {
      const checked = selections[key].has(value);

      return (
        <InputCheckbox
          key={filterIndex}
          label={value}
          name={label}
          value={value}
          checked={checked}
          onChange={() => setSelections({ set: { key, value, checked: !checked } })}
        />
      );
    });

    let initialExpanded = openGroup === key;

    if (!initialExpanded) {
      initialExpanded = !!queryFilters.find(f => f.startsWith(`${key}~`));
    }

    return (
      <ContentAccordion
        key={index}
        id={`filter-group-${key}`}
        heading={<h5>{label}</h5>}
        initialExpanded={initialExpanded}
      >
        {checkboxes}
      </ContentAccordion>
    );
  });

  const applyFiltersButton = ({ handleClose }) => {
    const handleApplyFilters = (ev) => {
      ev.preventDefault();

      setQueryFilters(
        Array.from(Object.keys(selections)).reduce((newQueryFilters, key) => {
          if (selections[key].size === filters[key].config.options.length) {
            return newQueryFilters;
          }

          return newQueryFilters.concat(Array.from(selections[key]).map(f => `${key}~${f}`));
        }, [])
      );

      handleClose();
    };

    return (
      <Button className='red-btn' onClick={handleApplyFilters}>Apply Filter</Button>
    );
  };

  const handleClearFilters = useCallback(() => {
    setSelections({ clear: true });
  }, []);

  const clearFiltersButton = useMemo(() => (
    <Button className='wht-btn' onClick={handleClearFilters}>Clear Filters</Button>
  ), [ handleClearFilters ]);

  return (
    <BasicModal
      heading='Filter Jobs'
      className="fwrd-jobs-filters-modal"
      primaryButton={applyFiltersButton}
      secondaryButton={clearFiltersButton}
      isForm={true}
      onClose={onClose}
    >
      {accordions}
    </BasicModal>
  );
}
