import React from 'react';
import { Img } from '@maxmedia/mxm-react-lib';
import './styles.scss';

export function ArticleHero ({
  image,
  alt
}) {
  return (
    <div className="fwrd-article-hero">
      <div className="hero-image">
        <Img image={ image } alt={ alt ?? '' }/>
      </div>
    </div>
  );
}

export function CategoryHero ({
  title,
  description
}) {
  return (
    <div className="fwrd-category-hero">
      <div className="hero-content container --small">
        <h1 className="hero-title">{`${ title } Category`}</h1>
      </div>
    </div>
  );
}
