import React, { useState } from 'react';
import { Modal, Fade, Box } from '@mui/material';
import { Button } from '../button';
import { CloseIcon } from '../icons';
import './styles.scss';

export function BasicModal ({
  children,
  heading,
  subheading,
  className,
  primaryButton,
  secondaryButton,
  onClose = () => {},
  isCrit = false,
  isForm = false
}) {
  const [ isOpen, setIsOpen ] = useState(true);

  const handleClose = (...args) => {
    setIsOpen(false);
    onClose(...args);
  };

  const classNamesRendered = [
    'fwrd-modal',
    className ?? '',
    isForm ? 'form-modal' : '',
    isCrit ? 'crit' : ''
  ];

  let headingRendered = (
    <div className='basic-modal__head'>
      <h1>{heading}</h1>
    </div>
  );

  if (isForm) {
    headingRendered = (
      <div className='form-head'>
        <h1>{heading}</h1>
        <span>{subheading}</span>
      </div>
    );
  }

  if ('function' == typeof (primaryButton)) {
    primaryButton = primaryButton({ handleClose });
  }

  if ('function' == typeof (secondaryButton)) {
    secondaryButton = secondaryButton({ handleClose });
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      className={classNamesRendered.join(' ')}>
      <Fade in={isOpen}>
        <Box className='box'>
          <div className='paper'>
            {headingRendered}
            <div className="modal-content">{children}</div>
            <div className='buttons'>
              <Button className='dsk-only' onClick={handleClose}>Cancel</Button>
              <div className='const-btns'>
                {primaryButton ? primaryButton : null}
                {secondaryButton ? secondaryButton : null}
              </div>
            </div>
            <CloseIcon
              className='close-icon'
              onClick={handleClose}
            />
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
