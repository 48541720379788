/* eslint-disable react/jsx-max-depth */
import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BackgroundImage, Img } from '@maxmedia/mxm-react-lib';
import { Button, TextInput, FwrdDatePicker } from '../../../../components';
import ToolbarButton from './toolbar-button';
import { imageFocusToObjectPosition } from '../../../../utils/image-focus';
import { useMergePrismicPreviewData } from '../../../../utils/use-merge-prismic-preview-data';
import { RightArrowIcon } from '../../../icons';
import './styles.scss';

const COLOR_MODE_CLASS = {
  'Light mode': '--light',
  'Dark mode': '--dark'
};

export function Hero ({ primary, items, slice_label }) {
  const { heroContent, image, imageFocus, colorMode, showSocialLinks } = primary;
  const _globalData = useStaticQuery(graphql`
    query HeroGlobalsQuery {
      prismicGlobalOptions {
        _previewable
        data {
          heroSocialLinks {
            icon { alt, gatsbyImageData }
            link { url, target }
          }
        }
      }
    }
  `);
  const globalData = useMergePrismicPreviewData(_globalData);
  const toolbarRendered = useMemo(() => {
    if (!items.length) return null;

    const itemsMobile = items.filter(item => item.showOnMobile);

    if (itemsMobile.length > 1 && itemsMobile[itemsMobile.length - 1].primaryButton) {
      itemsMobile[itemsMobile.length - 2].isMobileBeforePrimary = true;
    }

    const buttons = items.map((item, i) => {
      const {
        primaryButton,
        toolbarButtonIcon,
        altToolbarButtonIcon,
        toolbarButtonLink,
        toolbarButtonText,
        showOnMobile,
        isMobileBeforePrimary
      } = item;

      return (
        <ToolbarButton
          key={`toolbar-button-${i}`}
          primaryButton={primaryButton}
          icon={toolbarButtonIcon}
          altIcon={altToolbarButtonIcon}
          text={toolbarButtonText}
          link={toolbarButtonLink}
          showOnMobile={showOnMobile}
          isMobileBeforePrimary={isMobileBeforePrimary || items[i+1]?.primaryButton}
        />
      );
    });

    return (
      <div className="hero-toolbar">
        <div className="hero-toolbar__inner container">
          {buttons}
        </div>
      </div>
    );
  }, [ items ]);

  let className = `
    fwrd-hero
    ${COLOR_MODE_CLASS[colorMode] ?? ''}
    ${items.length ? 'with-toolbar' : ''}
  `;

  let heroClassName = 'hero__inner';
  let headerClassName = 'header';
  let contentClassName = 'content';

  const heroSocialLinks = globalData.prismicGlobalOptions.data.heroSocialLinks;
  let socialLinks = null;

  if (showSocialLinks && heroSocialLinks?.length) {
    const socialLinks_ = heroSocialLinks.map((socialLink, i) => {
      return (
        <a key={i} href={socialLink.link.url} target={socialLink.link.target}>
          <Img image={socialLink.icon} />
        </a>
      );
    });
    socialLinks = ( <div className="social-links">{socialLinks_}</div> );
  }

  let quote_formQuoteFormRendered = null;
  if (slice_label === 'quote_form') {
    heroClassName += ' quote_form';
    quote_formQuoteFormRendered =
    (
      <div className='quote_form__inputs'>
        <div className='name-inputs'>
          <TextInput
            className='first-name-input'
            id="first-name"
            label="First Name"
          />
          <TextInput
            className='last-name-input'
            id="last-name"
            label="Last Name"
            variant="filled"
          />
        </div>
        <div className='email-button'>
          <TextInput
            className='email-input'
            id="email"
            label="Email"
            variant="filled"
            type="email"
          />
          <Button className='careers__button' variant="contained">
            Get Started
          </Button>
        </div>
      </div>
    );
  }

  let quote_form2QuoteFormRendered = null;
  if (slice_label === 'quote_form2') {
    heroClassName += ' quote_form2';
    className += ' after-quote_form2';
    quote_form2QuoteFormRendered = (
      <form className='quote_form2__form'>
        <div className='quote_form2__inputs'>
          <div className='location-inputs'>
            <TextInput
              className='location-input'
              id="location"
              label="City, State or Zip code"
            />
            <RightArrowIcon className="right-arrow"/>
            <TextInput
              className='destination-input'
              id="destination"
              label="Destination"
              variant="filled"
            />
          </div>
          <FwrdDatePicker
            className='date-input'
            id="date"
            label="Date"
            variant="filled"
            type="date"
          />
        </div>
        <Button className='quote_form2__button' variant="contained">
          Start a Quote
        </Button>
      </form>
    );
  }

  let careerFormRendered = null;
  if (slice_label === 'careers') {
    heroClassName += ' careers';
    headerClassName = headerClassName.replace(/^/, 'careers__');
    careerFormRendered = (
      <div className='careers__inputs'>
        <div className='post-hold'>
          <TextInput
            className='postings-input'
            id="postings"
            label="search postings"
          />
        </div>

        <div className='input-flex'>
          <TextInput
            className='location-input'
            id="location"
            label="location"
            variant="filled"
          />
          <Button className='careers__button'>
            Find Job
          </Button>
        </div>
      </div>

    );
  }

  return (
    <section className={className}>
      <div className={heroClassName}>
        <div className="hero-content">
          <div className="hero-content__inner container">
            <div className={contentClassName}>
              <div
                className={headerClassName}
                dangerouslySetInnerHTML={{ __html: heroContent.html }} />
              {socialLinks}
              {quote_formQuoteFormRendered}
              {quote_form2QuoteFormRendered}
              {careerFormRendered}
            </div>
          </div>
        </div>
        <div className="hero-image">
          <BackgroundImage
            image={image.gatsbyImageData}
            objectPosition={imageFocusToObjectPosition(imageFocus, 'right')}
          />
        </div>
        { toolbarRendered }
      </div>
    </section>
  );
}

export const query = graphql`
fragment homepageHeroFields on PrismicHomepageDataBodyHero {
  id, slice_type, slice_label

  primary {
    imageFocus, colorMode
    heroContent { html }
    image {
      gatsbyImageData
      alt
    }
  }

  items {
    primaryButton, showOnMobile
    toolbarButtonLink { url }
    toolbarButtonText { text }
    toolbarButtonIcon {
      gatsbyImageData
      alt
    }
    altToolbarButtonIcon {
      gatsbyImageData
      alt
    }
  }
}

fragment pageHeroFields on PrismicPageDataBodyHero {
  id, slice_type, slice_label

  primary {
    imageFocus, colorMode
    heroContent { html }
    image {
      gatsbyImageData
      alt
    }
    showSocialLinks
  }

  items {
    primaryButton, showOnMobile
    toolbarButtonLink { url }
    toolbarButtonText { text }
    toolbarButtonIcon {
      gatsbyImageData
      alt
    }
    altToolbarButtonIcon {
      gatsbyImageData
      alt
    }
  }
}

fragment the404PageHeroFields on Prismic404PageDataBodyHero {
  id, slice_type, slice_label

  primary {
    imageFocus, colorMode
    heroContent { html }
    image {
      gatsbyImageData
      alt
    }
    showSocialLinks
  }

  items {
    primaryButton, showOnMobile
    toolbarButtonLink { url }
    toolbarButtonText { text }
    toolbarButtonIcon {
      gatsbyImageData
      alt
    }
    altToolbarButtonIcon {
      gatsbyImageData
      alt
    }
  }
}
`;
