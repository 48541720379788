/* eslint-disable max-len */
import React from 'react';
import { graphql } from 'gatsby';
import { Img, LinkWrapper } from '@maxmedia/mxm-react-lib';
import './styles.scss';

export function ServicesOverview ({ primary, items, url }) {
  const { content } = primary;

  const gridItems = items.map((item, i) => (
    <LinkWrapper key={i} className="grid-item" url={item.link.url ?? url}>
      <Img image={item.image} alt={''} />
      <span>{item.name.text}</span>
    </LinkWrapper>
  ));

  return (
    <div className='fwrd-services-overview'>
      <div className='services-overview__inner container --small'>
        <div className="services-overview__content">
          <div className="content" dangerouslySetInnerHTML={{ __html: content.html }}></div>
        </div>
        <div className="services-overview__icon-grid">
          {gridItems}
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
fragment homepageServicesOverviewFields on PrismicHomepageDataBodyServicesOverview {
      id, slice_type, slice_label

      primary {
        content { html }
      }

      items {
        image { gatsbyImageData, alt }
        name { text }
        link { url }
      }
    }
`;
