import React, { useState } from 'react';
import { BasicModal } from '../modal';
import { TextInput } from '../form-inputs';
import { Button } from '../button';
import './styles.scss';

export function ContactModal ({
  onClose = () => {}
}) {
  const handleClose = (...args) => {
    onClose(...args);
  };

  return (
    <BasicModal
      className='fwrd-contact-modal'
      heading='Contact Us Directly'
      subheading='Leave a message to send us an email.'
      primaryButton={<Button className='red-btn'>Start</Button>}
      onClose={handleClose}
      isForm
    >
      <form className='contact-modal-form'>

        <h1 className='claims-header'>Claims</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

        <h2 className='contact-info-header'>Individual Contact Information</h2>
        <TextInput
          className='full-name'
          label='Full Name'
          required
        />
        <TextInput
          className='user-email'
          label='Email'
          type='email'
          required
        />

        <TextInput
          className='user-message'
          label='Type Your Message Here'
          required
        />
      </form>

    </BasicModal>
  );
}
