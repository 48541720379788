import React, { useState, useEffect } from 'react';
import { FormLabel } from '@mui/material';
import { AlertBar, InputFieldWithChips } from '..';
import { TrackingResult } from './tracking-result';
import './styles.scss';

export function TrackingWidget ( { label, listingContent, statuses, changeNumbers, numbers }) {
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ trackingResult, setTrackingResult ] = useState({});
  let items = {};

  useEffect(() => {
    const trackShipments = async () => {
      try {
        const res = await fetch(`https://api.dev.www.forwardair.mxmcloud.com/tracking/shipments?numbers=${numbers}`, {
          method: 'GET',
          headers: {
            accept: 'application/json'
          }
        });

        if (!res.ok) {
          throw new Error(`Error! status: ${res.status}`);
        }

        return await res.json();
      } catch (err) {
        console.error(err);
      }
    };

    if (numbers) {
      trackShipments(numbers).then(result => {
        setTrackingResult(result);
      });
    }
  }, [ numbers ]);

  const handleSelectedTags = (chippedItems) => {
    items = chippedItems;
  };

  const handleButtonClick = () => {
    if (items.length <= 0) {
      setErrorMessage('Please add a tracking number');
      return false;
    }
    changeNumbers(items);
  };

  return (
    <div className="fwrd-tracking-widget">
      <div className="fwrd-tracking-fields container">
        <FormLabel htmlFor="fwrd-tracking-tags" className="fwrd-tracking-label">{label}</FormLabel>
        <InputFieldWithChips
          selectedTags={handleSelectedTags}
          fullWidth
          id="fwrd-tracking-tags"
          name="fwrd-tracking-tags"
          handleButtonClick={handleButtonClick}
          tags={numbers ? numbers : []}
        />
        {
          errorMessage &&
            <FormLabel className="error"> { errorMessage } </FormLabel>
        }
      </div>
      {
        !trackingResult ?
          <AlertBar
            alertId={`tracking-result-alert-bar`}
          >
            There was a problem retrieving the tracking result
          </AlertBar>
          :
          <TrackingResult
            listingContent={listingContent}
            trackingResult={trackingResult}
            statuses={statuses}/>
      }
    </div>
  );
}
