import React, { useState, useCallback } from 'react';
import './styles.scss';

export function CollectionFilters ({
  filters,
  onChange = () => {}
}) {
  const [ activeFilter, setActiveFilter ] = useState(null);

  const handleClick = useCallback((val, ev) => {
    ev.preventDefault();
    setActiveFilter(val);
    onChange(val);
  }, [ onChange ]);

  const filtersRendered = filters.map(({ label, value }, index) => (
    <li className="tag-link-title" key={index}>
      <a
        className={`tag-link ${activeFilter === value ? 'active' : ''}`}
        onClick={handleClick.bind(null, value)}
        onKeyPress={handleClick.bind(null, value)}
        role='tab'
        tabIndex="0"
      >
        {label}
      </a>
    </li>
  ));

  return (
    <section className="fwrd-collection-filters">
      <div className="filter-wrapper">
        <ul className="inner-wrapper">
          {filtersRendered}
        </ul>
      </div>
    </section>
  );
}
