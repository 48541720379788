import React from 'react';
import { graphql } from 'gatsby';
import { Img } from '@maxmedia/mxm-react-lib';
import { Card } from '../../../../components';
import './styles.scss';

export function LoginLanding ({ primary, items }) {
  const loginItems = items.map((item, i) => {
    const altCardIcon = !items[i].altIcon.gatsbyImageData ? null : (
      <Img image={(items[i].altIcon.gatsbyImageData)} alt='' className="alt-img-define"
        loading="eager" objectFit="contain" />
    );

    const cardIcon = !items[i].icon.gatsbyImageData ? null : (
      <Img image={(items[i].icon.gatsbyImageData)} alt='' className="img-define" loading="eager" objectFit="contain" />
    );

    return (
      <Card
        key={i}
        square="true"
        className='small'
        topIcon={cardIcon}
        altIcon={altCardIcon}
        heading={item.text}
        to={item.link.url}
      />
    );
  });
  return (
    <section className='fwrd-login-landing'>
      <div className='login-landing__inner container --small'>
        <Img image={primary.image1} alt={primary.image1.alt} className="login-image"/>
        <div className='login-container'>
          <div className='login-container__inner'>
            <div className='login-header wysiwyg-slice-content' dangerouslySetInnerHTML={{ __html: primary.contentBefore.html }} />
            <div className='card-container'>{loginItems}</div>
            <div className='register-content wysiwyg-slice-content' dangerouslySetInnerHTML={{ __html: primary.contentAfter.html }} />
          </div>
        </div>
      </div>

    </section>
  );
}

export const query = graphql`
fragment loginPageLoginLandingFields on PrismicLoginPageDataBodyLoginLanding {
  id, slice_type

  primary {
    image1 { gatsbyImageData, alt }
    contentBefore { html }
    contentAfter { html }
  }

  items {
    icon { gatsbyImageData }
    altIcon { gatsbyImageData }
    link { url }
    text
  }
}
`;
