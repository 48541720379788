import React from 'react';
import { Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { BookmarkIcon, MenuDotsIcon } from '../icons';
import './styles.scss';

export function ArticleListItem ({
  category,
  title,
  fixedDate,
  children
}) {
  let categoryRendered = null;

  if (category) {
    categoryRendered = (
      <CardHeader
        className="fwrd-articleList__cat"
        subheader={ category }/>
    );
  }

  let dateRendered = (dateStr) => {
    if (!dateStr) return null;
    const date = new Date(Date.parse(dateStr));
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return <p className="card-date">{ formattedDate }</p>;
  };

  if (fixedDate) {
    dateRendered = (
      <CardActions className="fwrd-articleList__actions">
        <span className="fwrd-articleList__actions__date">
          {fixedDate}
        </span>
        <div className="fwrd-articleList__actions__icons">
          <div className="fwrd-articleList__actions__icons__bookmark">
            <BookmarkIcon/>
          </div>
          <div className="fwrd-articleList__actions__icons__kebab">
            <MenuDotsIcon/>
          </div>
        </div>
      </CardActions>
    );
  }

  return (
    <Card
      square={true}
      className="fwrd-articleList">
      <div className="fwrd-articleList__header">
        {categoryRendered}
        <CardHeader
          className="fwrd-articleList__title"
          title={ title }>
        </CardHeader>
      </div>
      <CardContent className="fwrd-articleList__content">
        {children}
      </CardContent>
      {dateRendered}
    </Card>
  );
}
