import React, { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import { CloseIcon } from '../icons';
import { useMergePrismicPreviewData } from '../../utils/use-merge-prismic-preview-data';
import './styles.scss';

function getDismissedNtfId () {
  return localStorage.getItem('dismissedNtfList') ?? [];
}

//This is here incase we need to use local storage.
// function setDismissedNtfList (list) {
//   localStorage.setItem('dismissedNtfList', list);
// }

export function NewToForward ({
  ntfId
}) {
  const _data = useStaticQuery(graphql`
    query NewToForwardQuery {
      prismicGlobalOptions {
        _previewable
        data {
          newToForward { html }
        }
      }
    }
  `);
  const [ ntfOpen, setNtfOpen ] = useState(true);

  useEffect( () => {
    setNtfOpen( !getDismissedNtfId().includes(ntfId) );
  }, [ ntfId ]);

  const handleClose = () => {
    //This is here incase we need to use local storage.

    // const dismissedNtfId = getDismissedNtfId();
    // if ( !dismissedNtfId.includes(ntfId) ) {
    //   dismissedNtfId.push(ntfId);
    //   setDismissedNtfList(dismissedNtfId);
    // }

    setNtfOpen(false);
  };

  const data = useMergePrismicPreviewData(_data);
  const nwToFwrdHtml = data.prismicGlobalOptions.data.newToForward.html;
  return (
    <Collapse in={ntfOpen}>
      <div className='fwrd-new-to'>
        <div className='fwrd-new-to__content' dangerouslySetInnerHTML={{ __html: nwToFwrdHtml }}>
        </div>
        <CloseIcon
          onClick={ handleClose }
        />
      </div>
    </Collapse>

  );
}
