import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import './styles.scss';

export function LoadingSpinner ({
  className,
  show = true,
  size = 120,
  ...props
}) {
  const [ hide, setHide ] = useState(!show);

  className = `fwrd-loading ${className ?? ''}`;

  if (hide || !show) className += ' hide';

  if ('number' === typeof show) {
    setTimeout(() => setHide(true), show);
  }

  return (
    <CircularProgress className={className} size={size} {...props} />
  );
}
