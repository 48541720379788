import React from 'react';
import { Img, LinkWrapper } from '@maxmedia/mxm-react-lib';

const ToolbarButton = ({
  icon, altIcon, text, primaryButton, link, showOnMobile, isMobileBeforePrimary
}) => {
  const className = `
    toolbar-button
    ${primaryButton ? '--primary' : ''}
    ${showOnMobile ? '--mobile' : ''}
    ${isMobileBeforePrimary ? '--before-primary' : ''}
  `;
  return (
    <LinkWrapper url={link?.url ?? '#'} className={className}>
      <Img image={icon.gatsbyImageData} className="toolbar-icon" />
      <Img image={altIcon.gatsbyImageData} className="toolbar-alt-icon" />
      <h6>{text?.text ?? ''}</h6>
    </LinkWrapper>
  );
};

export default ToolbarButton;
