import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import Slider from '@maxmedia/react-slick';
import { graphql } from 'gatsby';
import { Img } from '@maxmedia/mxm-react-lib';
import { IconLeftArrow, IconRightArrow } from "../../../icons";
import './styles.scss';

function NextArrow (props) {
  const { className, style, onClick: handleClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={handleClick}
      onKeyDown={handleClick}
      role='button'
      tabIndex='0'>
      <IconRightArrow />
    </div>
  );
}

function PrevArrow (props) {
  const { className, style, onClick: handleClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={handleClick}
      onKeyDown={handleClick}
      role='button'
      tabIndex='0'>
      <IconLeftArrow />
    </div>
  );
}

function updateSlideHeights (slider) {
  const slideEls = slider.innerSlider.list.querySelectorAll('.image-slide');

  let maxHeight = 0;
  for (let slideEl of slideEls) {
    if (slideEl.clientHeight <= maxHeight) continue;
    maxHeight = slideEl.clientHeight;
  }

  for (let slideEl of slideEls) {
    slideEl.style.height = `${maxHeight}px`;
  }
}

export function ImageSlider ({ items }) {
  const contentSliderRef = useRef(null);
  const navSliderRef = useRef(null);
  const [ contentSlider, setContentSlider ] = useState(null);
  const [ navSlider, setNavSlider ] = useState(false);

  useEffect(() => {
    if (contentSliderRef?.current) {
      setContentSlider(contentSliderRef.current);
      updateSlideHeights(contentSliderRef.current);
    }

    if (items?.length > 7) {
      setNavSlider(true);
    }
  }, [ items ]);

  const contentSlidesRendered = useMemo(() => {
    if (!items || !items.length) return null;

    return items.map((slide, i) => {
      const {
        slideContent,
        slideImage
      } = slide;

      return (
        <div
          className="image-slide"
          key={`image-slide-${i}`}
        >
          <div className="img-container">
            <Img image={slideImage} objectFit="contain" />
          </div>
          <div className="content-container">
            <div
              className="slide-content"
              dangerouslySetInnerHTML={{ __html: slideContent.html }}
            />
          </div>
        </div>
      );
    });
  }, [ items ]);

  const navSlidesRendered = useMemo(() => {
    if (!items || !items.length) return null;

    const handleSlideClick = (index) => {
      contentSliderRef.current.slickGoTo(index);
      navSliderRef.current.slickGoTo(index);
    };

    return items.map((slide, i) => {
      const { slideImage } = slide;

      return (
        <div
          className="nav-slide"
          key={`nav-slide-${i}`}
          onClick={() => handleSlideClick(i)}
          onKeyDown={() => handleSlideClick(i)}
          role="button"
          tabIndex="0"
        >
          <div className="img-container">
            <Img image={slideImage} alt={slideImage.alt ?? ''} />
          </div>
        </div>
      );
    });
  }, [ items ]);

  let contentSliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    responsive: [ {
      breakpoint: 1280,
      settings: {
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      }
    } ]
  };

  const extendedContentSettings = {
    adaptiveHeight: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  if (!navSlider) {
    contentSliderSettings = { ...contentSliderSettings, ...extendedContentSettings };
  }

  const navSliderSettings = {
    slidesToShow: Math.min(items.length, 8),
    slidesToScroll: 1,
    infinite: true,
    focusOnSelect: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    asNavFor: contentSlider
  };

  const navSliderSection = () => {
    if (!navSlider) return;

    return (
      <div className="nav-slider-wrapper">
        <Slider
          ref={ navSliderRef }
          className="nav-slider"
          { ...navSliderSettings }
        >
          {navSlidesRendered}
        </Slider>
      </div>
    );
  };

  return (
    <section className="fwrd-image-slider slice-module">
      <div className="image-slider__inner container --small">
        <Slider
          ref={ contentSliderRef }
          className={`content-slider ${ !navSlider ? 'extended-settings' : '' }`}
          { ...contentSliderSettings }
        >
          { contentSlidesRendered }
        </Slider>
        { navSliderSection() }
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageImageSliderFields on PrismicPageDataBodyImageSlider {
  id
  slice_type

  items {
    slideImage {
      gatsbyImageData
    }
    slideContent {
      html
    }
  }
}
`;
