import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import './styles.scss';

export function ContentAccordion ({
  expanded,
  initialExpanded = false,
  onChange,
  heading,
  id,
  children
}) {
  const [ isExpanded, setIsExpanded ] = useState(initialExpanded);

  if (!onChange) {
    onChange = () => setIsExpanded(!isExpanded);
  }

  return (
    <Accordion
      className="fwrd-accordion"
      expanded={expanded ?? isExpanded}
      onChange={onChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <div className="accordion-heading">{heading}</div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="accordion__inner">
          <div className="accordion-body">{children}</div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
