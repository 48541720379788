import React from "react";
import { Button } from "../button";
import { ContentCard } from "../content-card";
import { LinkWrapper } from '@maxmedia/mxm-react-lib';
import "./styles.scss";

export function FeaturedArticles ({
  articles
}) {
  const articlesRendered = articles.map((item, index) => {
    const { url } = item.article.document;
    const { category, publishDate, title } = item.article.document.data;
    return (
      <LinkWrapper key={ index } url={ url }>
        <ContentCard
          featured
          category={ category }
          headline={ title.text }
          dateStamp={ publishDate }
        />
      </LinkWrapper>
    );
  });

  return (
    <div className="fwrd-feat-articles">
      <h2 className="feat-articles-header">Featured Articles</h2>
      <div className="feat-articles-well">
        { articlesRendered }
      </div>
      <div className="feat-articles-cta">
        <Button
          variant="contained"
          href="/"
          disableElevation
        >
          View All News
        </Button>
      </div>
    </div>
  );
}
