import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Chip, Pagination } from '@mui/material';
import { useQueryParam, ArrayParam, StringParam, withDefault } from 'use-query-params';
import { graphql } from 'gatsby';
import { Button, InputSelect } from '../../../../components';
import { CloseIcon } from '../../../icons';
import { CareersJobsList } from './careers-jobs-list';
import { CareersJobFilters } from './careers-job-filters';
import { DriversJobsList } from './drivers-jobs-list';
import { DriversJobFilters } from './drivers-job-filters';
import './styles.scss';

const SOTRING_OPTIONS = [
  { label: 'Date', value: 'date' },
  { label: 'Relevance', value: 'relevance' },
  { label: 'Distance', value: 'distance' }
];

function ChipList ({ selectedFilters, queryFilters, setQueryFilters }) {
  const chips = Array.from(Object.keys(selectedFilters)).reduce((memo, key) => {
    return memo.concat(
      Array.from(selectedFilters[key]).map((value) => {
        const qf = `${key}~${value}`;

        const handleDelete = () => {
          setQueryFilters(queryFilters.filter(f => f !== qf));
        };

        return (
          <li key={qf}>
            <Chip
              icon={null}
              deleteIcon={<CloseIcon />}
              label={value}
              onDelete={handleDelete}
            />
          </li>
        );
      })
    );
  }, []);

  return (
    <ul className="fwrd-chiplist">{chips}</ul>
  );
}

export function JobListings ({ primary }) {
  const [ itemsLength, setItemsLength ] = useState(0);
  const [ queryFilters, setQueryFilters ] = useQueryParam('f', withDefault(ArrayParam, []));
  const [ querySearch ] = useQueryParam('s', withDefault(StringParam, false));
  const [ queryLocation ] = useQueryParam('l', withDefault(StringParam, false));
  const [ page, setPage ] = useState(1);
  const [ sortValue, setSortValue ] = useState('date');
  const perPage = 10;

  useEffect(() => {
    if (querySearch) setSortValue('relevance');
  }, [ querySearch ]);

  useEffect(() => {
    if (queryLocation) setSortValue('distance');
  }, [ queryLocation ]);

  const selectedFilters = useMemo(() => (
    queryFilters.reduce((memo, f) => {
      const [ key, value ] = f.split('~');

      if (!key || !value) {
        console.warn(`Invalid f: ${JSON.stringify(f)}`);
        return memo;
      }

      if (!memo[key]) memo[key] = new Set();

      memo[key].add(value);

      return memo;
    }, {})
  ), [ queryFilters ]);

  const handlePageChange = useCallback((ev, pageNum) => {
    setPage(pageNum);
  }, []);

  const handleClearAll = useCallback(() => {
    if (!queryFilters.length) return;
    setQueryFilters([]);
  }, [ queryFilters, setQueryFilters ]);

  const handleSortChange = useCallback((ev) => {
    setSortValue(ev.target.value);
  }, []);

  const pageStartNum = useMemo(() => (
    page === 1 ? 1 : (page - 1) * perPage + 1
  ), [ page, perPage ]);

  const currentPage = useMemo(() => [
    pageStartNum, '-', Math.min(perPage * page, itemsLength), 'of', itemsLength
  ].join(' '), [ pageStartNum, perPage, page, itemsLength ]);

  const { heading, helpfulLinks, jobsType } = primary;

  let filters = null;
  let list = null;
  if (jobsType === 'Corporate') {
    filters = ( <CareersJobFilters selectedFilters={selectedFilters} /> );
    list = (
      <CareersJobsList
        pageStartNum={pageStartNum}
        perPage={perPage}
        onLengthFound={setItemsLength}
        filters={selectedFilters}
        search={querySearch}
        queryLocation={queryLocation}
        sortValue={sortValue}
      />
    );
  } else if (jobsType === 'Drivers') {
    filters = ( <DriversJobFilters selectedFilters={selectedFilters} /> );
    list = (
      <DriversJobsList
        pageStartNum={pageStartNum}
        perPage={perPage}
        onLengthFound={setItemsLength}
        filters={selectedFilters}
        search={querySearch}
        queryLocation={queryLocation}
        sortValue={sortValue}
      />
    );
  } else {
    console.warn(`Invalid jobsType: ${jobsType}`);
    return null;
  }

  return (
    <section className="job-listings-body">
      <div className="job-listings-body__inner container">
        <div className="heading-content">
          <div className="heading-container">
            <div className="body-title" dangerouslySetInnerHTML={{ __html: heading.html }} />
            <div className="sort-dropdown">
              <span className="sort-by-label">Sort By:</span>
              <InputSelect
                options={SOTRING_OPTIONS}
                handleChange={handleSortChange}
                value={sortValue}
                id="job-listings-sortby"
              />
            </div>
          </div>
          <div className="filters-container">
            <h6 className="filter-heading">Filter By:</h6>
            {filters}
            <div className="filter-heading-wrapper">
              <h6 className="filter-heading">Active Filters</h6>
              <Button variant="text" onClick={handleClearAll}>
                Clear All
              </Button>
            </div>
            <div className="active-chips">
              <ChipList
                selectedFilters={selectedFilters}
                queryFilters={queryFilters}
                setQueryFilters={setQueryFilters}
              />
            </div>
          </div>
        </div>
        <div className="main-content">
          <div className="job-list-container">
            {list}
            <div className="pagination-container">
              <div className="page-count-info">
                <span className="per-page">{perPage} items per page</span>
                <span className="curr-page">{currentPage}</span>
              </div>
              <Pagination
                count={Math.floor(itemsLength / perPage) + 1}
                shape="rounded"
                page={page}
                onChange={handlePageChange}
              />
            </div>
          </div>
          <aside className="sidebar">
            <div className="helpful-links" dangerouslySetInnerHTML={{ __html: helpfulLinks.html }} />
          </aside>
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageJobListingsFields on PrismicPageDataBodyJobListings {
  id, slice_type

  primary {
    heading { html }
    helpfulLinks { html }
    jobsType # Corporate, Drivers
  }
}
`;
