import React, { useEffect, useState } from 'react';
import { Collapse, Alert, AlertTitle } from '@mui/material';
import { Button } from '../button';
import { CloseIcon, WarningIcon } from '../icons';
import './styles.scss';

function getDismissedAlertIds () {
  return sessionStorage.getItem('dismissedAlertsList')?.match(/(\S+)+/g) ?? [];
}

function setDismissedAlertsList (list) {
  sessionStorage.setItem('dismissedAlertsList', list.join(' '));
}

export const AlertBar = ({
  alertId,
  alertTitle,
  children
}) => {
  const [ alertOpen, setAlertOpen ] = useState(false);

  useEffect( () => {
    setAlertOpen( !getDismissedAlertIds().includes(alertId) );
  }, [ alertId ]);

  const handleClose = () => {
    const dismissedAlertIds = getDismissedAlertIds();

    if ( !dismissedAlertIds.includes(alertId) ) {
      dismissedAlertIds.push(alertId);
      setDismissedAlertsList(dismissedAlertIds);
    }

    setAlertOpen(false);
  };

  return (
    <Collapse in={alertOpen}>
      <Alert
        action={
          <Button onClick={handleClose} disableRipple>
            <CloseIcon />
          </Button>
        }
        icon={ <WarningIcon /> }
        classes={{ root: 'fwrd-alert-bar' }}
      >
        <AlertTitle>{alertTitle}</AlertTitle>
        {children}
      </Alert>
    </Collapse>
  );
};

export const AlertNotification = ({
  alertId,
  alertTitle,
  actionText,
  ctaText,
  ctaLink,
  children
}) => {
  const [ alertOpen, setAlertOpen ] = useState(false);

  useEffect( () => {
    setAlertOpen( !getDismissedAlertIds().includes(alertId) );
  }, [ alertId ]);

  const handleClose = () => {
    const dismissedAlertIds = getDismissedAlertIds();

    if ( !dismissedAlertIds.includes(alertId) ) {
      dismissedAlertIds.push(alertId);
      setDismissedAlertsList(dismissedAlertIds);
    }

    setAlertOpen(false);
  };

  // if (alertOpen === false) return null;

  return (
    <Collapse in={alertOpen}>
      <Alert
        action={ <Button disableRipple><CloseIcon /></Button> }
        onClick={ handleClose }
        icon={ <WarningIcon /> }
        classes={{ root: 'fwrd-alert-notification' }}
      >
        <AlertTitle>{ alertTitle }</AlertTitle>
        <div className="MuiAlertContent-root">
          { children }
        </div>
        <div className="MuiAlertContent-root">
          { actionText ? <h3 className="alert-content__header">{ actionText }</h3> : null }
          <Button
            variant="outlined"
            onClick={ handleClose }
          >
            Close
          </Button>
          <Button
            variant="contained"
            href={ ctaLink }
            disableElevation
          >
            { ctaText }
          </Button>
        </div>
      </Alert>
    </Collapse>
  );
};
