import React from 'react';
import { Card as MuiCard, CardHeader } from '@mui/material';
import { LinkWrapper } from '@maxmedia/mxm-react-lib';
import './styles.scss';

export function Card ({
  heading,
  subheading,
  avImage,
  topIcon,
  altIcon,
  className,
  children,
  ctaButton,
  to,
  onClick,
  onKeyPress = () => {},
  ...props
}) {
  const card = (
    <MuiCard
      square={true}
      className={`fwrd-card ${className}`}
      elevation={0}
      {...props}
    >
      {topIcon}
      {altIcon}
      <CardHeader
        avatar={avImage}
        title={heading}
        subheader={subheading}
        className='header headline --small'
      />
      {children}
      {ctaButton}
    </MuiCard>
  );

  if (to) {
    return (
      <LinkWrapper url={to} className="fwrd-card-wrapper">
        {card}
      </LinkWrapper>
    );
  }

  if (onClick) {
    return (
      <a
        onClick={onClick}
        onKeyPress={onKeyPress}
        className="fwrd-card-wrapper"
        role="button"
        tabIndex={0}
      >
        {card}
      </a>
    );
  }

  return (
    <div className="fwrd-card-wrapper">
      {card}
    </div>
  );
}
