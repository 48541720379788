import React from 'react';
import { LinkWrapper } from '@maxmedia/mxm-react-lib';
import { Card, CardMedia } from '@mui/material';
import { Img } from '@maxmedia/mxm-react-lib';
import { Button } from '../../components';

export function CtaCard ({
  title,
  extendClasses,
  overlay,
  ctaTitle,
  ctaTarget,
  ctaImage
}) {
  let ctaImageRendered;

  if (ctaImage.gatsbyImageData) {
    ctaImageRendered = <Img image={ctaImage} alt={ctaImage.alt ?? ''} />;
  } else {
    ctaImageRendered = <CardMedia image={ ctaImage } />;
  }

  return (
    <Card className={`fwrd-cta-card ${extendClasses}`} target={ ctaTarget } elevation={0}>
      <LinkWrapper url={ctaTarget} className="ctaTargetLink">
        <div className={`card-media ${ overlay ? 'card-overlay' : null }`}>
          {ctaImageRendered}
          <h3 className="card-header">{ title }</h3>
        </div>
        <Button className="fullwidth-cta" variant="contained" disableRipple>
          <p className="cta-title">{ ctaTitle }</p>
        </Button>
      </LinkWrapper>
    </Card>
  );
}
