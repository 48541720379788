import React from 'react';
import { graphql } from 'gatsby';
import './styles.scss';

export function ArticleContent ({ primary }) {
  return (
    <section className='fwrd-article-content'>
      <div className='article-content__inner container --small'>
        <div
          className="article-body"
          dangerouslySetInnerHTML={{ __html: primary.content1.html }}
        />
      </div>
    </section>

  );
}

export const query = graphql`
fragment articleArticlesContentFields on PrismicArticlesDataBodyArticleContent {
  id, slice_type

  primary {
    content1 { html }
  }
}

fragment articlePageContentFields on PrismicPageDataBodyArticleContent {
  id, slice_type

  primary {
    content1 { html }
  }
}
`;
