import React from 'react';
import { graphql } from 'gatsby';
import { Img } from '@maxmedia/mxm-react-lib';
import { Button, Card } from '../../../../components';
import { columnsContentPrismicClasses } from '../columns-content';
import './styles.scss';

export function ServiceNavigationCards ({ items, primary, slice_label='' }) {
  const prismicClasses = columnsContentPrismicClasses(primary);

  const slice_label_class = () => {
    if (slice_label === 'long_cards') {
      return 'long_cards';
    } else if (slice_label === 'large_cards') {
      return 'large_cards';
    }

    return 'small_cards';
  };

  const sliceClass = `fwrd-nav-cards ${ slice_label_class() } ${prismicClasses}`;
  const containerClass = `container ${slice_label === '' ? '--small-medium' : '--small'}`;
  const cards = items.map((item, i) => {
    const { linkText, description, link } = item;

    const cardDescription = !description?.html ? null : (
      <div className='card-desc' dangerouslySetInnerHTML={{ __html: description.html }} />
    );

    const cardIcon = !item.icon?.gatsbyImageData ? null : (
      <Img image={item.icon} alt='' className="img-define" loading="eager" objectFit="contain" />
    );

    const altCardIcon = !item.iconAltState?.gatsbyImageData ? null : (
      <Img
        image={item.iconAltState}
        alt=''
        className="alt-img-define"
        loading="eager"
        objectFit="contain"
      />
    );

    return (
      <Card
        key={i}
        className={`nav-card ${altCardIcon ? '' : 'no-alt-icon'}`}
        topIcon={cardIcon}
        altIcon={altCardIcon}
        heading={linkText}
        to={link.url}
        square={true}

      >
        {cardDescription}
      </Card>
    );
  });
  let ctaRendered = null;

  if (primary.ctaLink.url) {
    ctaRendered = <Button variant="contained" className='cta' href={primary.ctaLink.url}>{primary.ctaText}</Button>;
  }

  return (
    <div className={sliceClass}>
      <div className={ containerClass }>
        {cards}
        {ctaRendered}
      </div>
    </div>
  );
}

export const query = graphql`
fragment pageServiceNavigationCardsFields on PrismicPageDataBodyServiceNavigationCards {
  id, slice_type, slice_label

  primary {
    backgroundColor
    diagonalPosition
    diagonalColor
    ctaLink { url }
    ctaText
  }

  items {
    link { url }
    linkText
    description { html }
    icon { gatsbyImageData }
    iconAltState { gatsbyImageData }
  }
}

fragment articlesServiceNavigationCardsFields on PrismicArticlesDataBodyServiceNavigationCards {
  id, slice_type, slice_label

  primary {
    backgroundColor
    diagonalPosition
    diagonalColor
    ctaLink { url }
    ctaText
  }

  items {
    link { url }
    linkText
    description { html }
    icon { gatsbyImageData }
    iconAltState { gatsbyImageData }
  }
}
`;
