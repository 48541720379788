import React from 'react';

export function StatusBar ({ resultDetails, statuses, color }) {
  const progressStates = [ "Processing", "Preparing", "Shipping", "Shipped" ];
  const shippingProgress = statuses.find(
    x => x.statusCode === resultDetails.CurrentStatusCode
  );
  const shippingProgressIndex = progressStates.indexOf(shippingProgress.progressState);
  const progressElements = [];
  const progressLabels = [];
  let style = { backgroundColor: color };
  let legendStyle = { color: "#2d2d2b" };

  progressStates.forEach((progressStatus, i) => {
    if (i > shippingProgressIndex) {
      style = {};
      legendStyle = {};
    }
    progressElements.push( <div className="bar" key={i} style={ style } />);
    progressLabels.push( <div className="legend" key={i} style={ legendStyle }>{progressStatus}</div>);
  });

  return (
    <div className="fwrd-status-bar">
      <div className="bars">
        {progressElements}
      </div>
      <div className="legend">
        {progressLabels}
      </div>
    </div>
  );
}
