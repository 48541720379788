import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import { TextInput } from '../form-inputs';
import './styles.scss';

export function FwrdSlider ({
  min,
  max,
  marks,
  valueLabelDisplay
}) {
  const [ sliderValue, setSliderValue ] = useState(30);

  const handleSliderChange = (ev, newValue) => {
    setSliderValue(newValue);
  };

  const handleInputChange = (ev) => {
    setSliderValue(ev.target.value === '' ? '' : Number(ev.target.value));
  };

  return (
    <div className="fwrd-slider">
      <Slider className="core-slide"></Slider>
      <div className='slide-w-input'>
        <Slider
          min={min}
          max={max}
          marks={marks}
          valueLabelDisplay={valueLabelDisplay}
          value={typeof sliderValue === 'number' ? sliderValue : 0}
          onChange={handleSliderChange}
          className="slide"/>
        <TextInput
          value={sliderValue}
          onChange={handleInputChange}/>
      </div>
    </div>
  );
}
