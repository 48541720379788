import React from 'react';
import { graphql } from 'gatsby';
import { Img } from '@maxmedia/mxm-react-lib';
import './styles.scss';

export function ValuePropositions ({ items }) {
  const itemData = items.map( (item, index) => {
    return (
      <div key={index} className="items-container">
        <div className="header-container">
          <Img image={item.image} alt={item.image.alt} className="value-image"/>
          <div className="coloroverlay"></div>
          <div dangerouslySetInnerHTML={{ __html: item.heading.html }}/>
        </div>
        <div dangerouslySetInnerHTML={{ __html: item.text.html }}/>
      </div>
    );
  });

  return (
    <section className="fwrd-values-container">
      <div className='values-container__inner container --small'>
        { itemData }
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageValuePropositionsFields on PrismicPageDataBodyValuePropositions {
  id, slice_type

  items {
    image { alt, gatsbyImageData }
    heading { html }
    text { html }
  }
}
`;
