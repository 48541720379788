import React from 'react';
import { IconLinkArrow } from '../icons';
import './styles.scss';

export function HalfColumnContent ({
  colSpan,
  category,
  header,
  copy,
  ctaRow,
  callOut,
  callOutLink
}) {
  const colSpanClass = `wrapper-width__${ colSpan }`;

  function ctaRowRendered () {
    if (ctaRow) {
      return (
        <div className="__cta-row">
          { ctaRow }
        </div>
      );
    }
  }

  function calloutRendered () {
    if (callOutLink) {
      return (
        <a href={ callOutLink } className="__callout" alt={ callOut }>
          <span className="callout-text">{ callOut }</span>
          <span className="callout-arrow"><IconLinkArrow/></span>
        </a>
      );
    }

    if (callOut) {
      return (
        <p className="__callout">
          <span className="callout-text">{ callOut }</span>
        </p>
      );
    }
  }

  function contentBodyRendered () {
    let _cat, _head, _copy;

    if (category) {
      _cat = <h3 className="__category">{ category }</h3>;
    }

    if (header) {
      _head = <h2 className="__header">{ header }</h2>;
    }

    if (copy) {
      _copy = <p className="__copy">{ copy }</p>;
    }

    return (
      <>
        { _cat }
        { _head }
        { _copy }
      </>
    );
  }

  return (
    <div className="fwrd-half-column">
      <div className={`__inner-wrapper ${colSpanClass}`}>
        { contentBodyRendered() }
        { ctaRowRendered() }
        { calloutRendered() }
      </div>
    </div>
  );
}
