import React from 'react';
import { graphql } from 'gatsby';
import { Img, LinkWrapper } from '@maxmedia/mxm-react-lib';
import './styles.scss';

export function LogoFarm ({ primary, items }) {
  const logoItems = items.map( (item, index) => {
    return (
      <div key={index} className='logo-image'>
        <LinkWrapper url={item.link.url}><Img image={item.logo} alt={item.logo.alt} className="logo-images"/></LinkWrapper>
      </div>
    );
  });

  const headingClassNameMod = primary.headingSize ? '--normal' : '--small';

  return (
    <section className="fwrd-logo-farm">
      <div className='logo-container__inner container --small'>
        <div className='headline-container'>
          <div
            className={`heading wysiwyg-slice-content ${headingClassNameMod}`}
            dangerouslySetInnerHTML={{ __html: primary.heading.html }}
          />
          <div className='divider'></div>
        </div>

        <div className='logo-farm'>
          {logoItems}
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageLogoFarmFields on PrismicPageDataBodyLogoFarm {
  id, slice_type

  primary {
    heading { html }
    headingSize
  }

  items {
    logo { gatsbyImageData, alt }
    link { url }
  }
}
`;
