import React, { useState } from 'react';
import { Button } from '../../components';
import { ChevronDownIcon, ShareIcon } from '../icons';
import { JobSummary } from './job-summary';
import './styles.scss';

export { JobSummary };

export function JobListItem ({
  city,
  ctaButtonText = "Apply",
  id,
  postedAt,
  title,
  summaryData = [],
  listingOrigin,
  fullDescription,
  applyUrl
}) {
  const [ isActive, setActive ] = useState(false);

  /* shows/hides job description panel */
  const handleCollapsePanel = () => {
    setActive(!isActive);
  };

  const originClassName = listingOrigin === 'careers' ? 'careers-listing' : '';

  return (
    <div className={`job-list-item-container ${originClassName}`}>
      <div className="summary-wrapper">
        {/* start heading */}
        <div className="job-list-item-heading">
          <div className="header headline --small">
            {title}
            <div className="copy --small text-grey">{city}</div>
          </div>
        </div>
        <div className="job-list-item-heading --meta">
          <div className="header headline --small text-grey">
            <div className="copy --small">{postedAt}</div>
            <div className="copy --small">Job ID: {id}</div>
          </div>
        </div> {/* end heading */}

        <JobSummary summaryItems={summaryData} className={isActive ? 'active' : ''} />
      </div>

      {/*  start collapsible wrapper */}
      <div className={isActive ? 'list-collapsed-panel open' : 'list-collapsed-panel'}>

        {/* start expandable element */}
        <div className="list-expanded-panel">
          <div className="full-description">
            <div
              className="wysiwyg-slice-content"
              dangerouslySetInnerHTML={{ __html: fullDescription }}
            />
            <Button
              variant="contained"
              to={applyUrl}
              target="_blank"
            >{ctaButtonText}</Button>
          </div>
        </div> {/*  end expandable information */}

        {/* start expand/collapse action  */}
        <ul className="actions horizontal copy">
          <li className="collapse-expand-icon cursor-pointer text-grey">
            <a
              onClick={handleCollapsePanel}
              onKeyDown={null}
              role="button"
              tabIndex="0">
              <ChevronDownIcon /> {isActive ? 'Collapse' : 'Read Details'}
            </a>
          </li>
          <li className="text-align-right">
            <ShareIcon />
          </li>
        </ul> {/* end expand/collapse action */}

      </div> {/* end collapsible wrapper */}
    </div>
  );
}
