import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { LoadingSpinner } from '../../..';
import './styles.scss';

export function FuelSurcharges ({ primary }) {
  const [ data, setData ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    async function getItems () {
      const response = await fetch('https://www.forwardair.com/forwardair/servlet/fwrd.unix.servlet.FAServiceServlet?service=fuelsurchargeboth', {
        headers: { 'Accept': 'application/json' }
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const _data = await response.json();
      setData(_data.FSCResponse.FSCs.sort((a, b) => {
        return Date.parse(a.EffectiveDate) - Date.parse(b.EffectiveDate);
      }));
      setLoading(false);
    }

    getItems().catch((err) => {
      setLoading(false);
      console.error(err);
    });
  }, []);

  const thisWeekRendered = !data.length ? null : (
    <>
      <div className='week-header'>
        <h3>This Week</h3>
        <span className='date'>{data[0].EffectiveDate} - {data[0].EndDate}</span>
      </div>
      <div className='surcharges'>
        <div className='charge'>
          <strong>LTL</strong>
          <span className='charge-percent'>{data[0].SystemSurcharge}%</span>
        </div>
        <div className='charge'>
          <strong>TLX</strong>
          <span className='charge-percent'>{data[0].EUVSurcharge}%</span>
        </div>
        <div className='charge'>
          <strong>Freight Class</strong>
          <span className='charge-percent'>{data[0].ClassSurcharge}%</span>
        </div>
      </div>
    </>
  );

  const nextWeekRendered = data.length <= 1 ? null : (
    <>
      <div className='week-header'>
        <h3>Next Week</h3>
        <span className='date'>{data[1].EffectiveDate} - {data[1].EndDate}</span>
      </div>
      <div className='surcharges'>
        <div className='charge'>
          <strong>LTL</strong>
          <span className='charge-percent'>{data[1].SystemSurcharge}%</span>
        </div>
        <div className='charge'>
          <strong>TLX</strong>
          <span className='charge-percent'>{data[1].EUVSurcharge}%</span>
        </div>
        <div className='charge'>
          <strong>Freight Class</strong>
          <span className='charge-percent'>{data[1].ClassSurcharge}%</span>
        </div>
      </div>
    </>
  );
  return (
    <section className="fwrd-fuel-surcharges">
      <div className="container --small">
        <div className="header" dangerouslySetInnerHTML={{ __html: primary.contentBefore.html }} />
        <div className='fuel-surcharges'>

          <div className='week'>
            <LoadingSpinner show={loading} />
            {thisWeekRendered}
          </div>

          <div className='week'>
            <LoadingSpinner show={loading} />
            {nextWeekRendered}
          </div>
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment loginPageFuelSurchargesFields on PrismicLoginPageDataBodyFuelSurcharges {
  id, slice_type

  primary {
    contentBefore { html }
    contentAfter { html }
  }
}
`;
