import React, { useMemo, useState, useEffect } from 'react';
import { InputAdornment } from '@mui/material';
import { useQueryParams, StringParam } from 'use-query-params';
import { graphql } from 'gatsby';
import { TextInput, Button } from '../../../../components';
import { LocationIcon } from '../../../icons';
import './styles.scss';

export function Header ({ items, slice_label }) {
  const [ searchVal, setSearchVal ] = useState('');
  const [ locationVal, setLocationVal ] = useState('');
  const [ queryParams, setQueryParams ] = useQueryParams({
    l: StringParam,
    s: StringParam
  });
  let innerHeaderClass = 'header__inner --small';

  useEffect(() => {
    if (queryParams.s) setSearchVal(queryParams.s);
    if (queryParams.l) setLocationVal(queryParams.l);
  }, [ queryParams ]);

  const itemsRendered = useMemo(() => {
    if (!items || !items.length) return null;

    return items.map((item, i) => {
      let headerContent = item.column.html;
      return (
        <div key={i} dangerouslySetInnerHTML={{ __html: headerContent }} className="col">
        </div>
      );
    });
  }, [ items ]);

  if (slice_label === 'careers') {
    innerHeaderClass += ' job-search';
  }
  const searchBarRendered = useMemo(() => {
    if (slice_label !== 'careers') return null;

    const handleInputChange = (ev) => setSearchVal(ev.target.value);
    const handleLocationChange = (ev) => setLocationVal(ev.target.value);

    const handleSearchSubmit = (ev, from) => {
      if (from === 'keydown' && ev.key !== 'Enter') return;
      ev.preventDefault();

      setQueryParams({
        l: locationVal || undefined,
        s: searchVal || undefined
      });
    };

    return (
      <div className="listings-search-container">
        <TextInput
          label="Search Department, Role, or Keywords"
          id="listings-search-box"
          onChange={handleInputChange}
          value={searchVal}
          className="listing-search"
          onKeyPress={(ev) => handleSearchSubmit(ev, 'keydown')}
        />
        <TextInput
          label="Location"
          id="location-search"
          value={locationVal}
          onChange={handleLocationChange}
          className="location-search"
          endAdornment={
            <InputAdornment position="end">
              <LocationIcon />
            </InputAdornment>
          }
        />
        <div className="button-container">
          <Button
            variant="contained"
            onClick={handleSearchSubmit}
          >
            Search Jobs
          </Button>
        </div>
      </div>
    );
  }, [ slice_label, searchVal, locationVal, setQueryParams ]);

  return (
    <section className="fwrd-header">
      <div className="container --small">
        <div className={innerHeaderClass}>
          {itemsRendered}
          { searchBarRendered }
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageHeaderFields on PrismicPageDataBodyHeader {
  id, slice_type, slice_label

  items {
    column { html }
  }
}
`;
