import React from 'react';
import { Img } from '@maxmedia/mxm-react-lib';
import './styles.scss';

export function TrackingHero ({ image }) {
  return (
    <div className="fwrd-tracking-hero">
      <div className="hero-image">
        <Img image={image} alt="" />
      </div>
    </div>
  );
}
