import React, { useReducer, useEffect } from 'react';
import { Chip, Paper } from '@mui/material';
import { SuccessIcon } from '../icons';

export function SuccessChipsList ({ header, onChange, initialState }) {
  const [ chipList, setChipList ] = useReducer((state, { index, clicked }) => {
    state[index].clicked = !clicked;
    return Array.from(state);
  }, initialState);

  function handleClick ({ index, clicked }) {
    setChipList({ index, clicked });
  }

  useEffect( () => {
    if (!onChange) return;

    onChange(chipList);
  }, [ chipList, onChange ]);

  return (
    <div className="fwrd-success-chiplist">
      <Paper component="ul" className="fwrd-chiplist" elevation={0}>
        <div className="chiplist-header">
          { header }
        </div>
        <div className="chiplist-well">
          {
            chipList.map( (item, index) => {
              const { clicked } = item;
              return (
                <li key={ index }>
                  <Chip
                    icon={ clicked ? <SuccessIcon /> : null }
                    label={ item.label }
                    onClick={handleClick.bind(null, { index, clicked })}
                    className={ clicked ? 'clicked-chip' : null }
                  />
                </li>
              );
            })
          }
        </div>
      </Paper>
    </div>
  );
}
