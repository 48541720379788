import React from 'react';
import { graphql } from 'gatsby';
import { Button, Card, CtaCard } from '../../../../components';
import { IconLinkArrow } from '../../../icons';
import './styles.scss';

function GridCardNoImage (
  {
    cardContent,
    ctaText
  }) {
  return (
    <Card
      className='large'
      heading={<div dangerouslySetInnerHTML={{ __html: cardContent.html }}/>}
      ctaButton= {
        <Button
          variant="text"
          className="no-padding"
          startIcon={<IconLinkArrow/>}
          disableRipple
        >
          { ctaText }
        </Button>
      }
    />
  );
}

function GridCardWithImage ({
  image1,
  cardContent,
  link,
  ctaText
}) {
  return (
    <CtaCard
      className='fwrd-cta-card'
      title={cardContent.text}
      ctaImage={image1}
      ctaTarget={link.url}
      ctaTitle={ctaText}
      overlay
    />
  );
}

export function NavigationGrid ({ primary, items, slice_label }) {
  const classes = `fwrd-services-cards ${slice_label ? slice_label : ''}`;

  const itemData = items.map((item, index) => {
    if (slice_label !== null ) {
      return (
        <div key={index} className={classes}>
          <GridCardNoImage { ...item } />
        </div>
      );
    }

    return (
      <div className='fwrd-industries-cards' key={index}>
        <GridCardWithImage { ...item } />
      </div>
    );
  });

  return (
    <section className='fwrd-navigation-grid'>
      <div
        className='wysiwyg-slice-content'
        dangerouslySetInnerHTML={{ __html: primary.contentBefore?.html }}
      />

      <div className='navigation-grid__inner container --small'>
        { itemData }
      </div>

      <div
        className='wysiwyg-slice-content'
        dangerouslySetInnerHTML={{ __html: primary.contentAfter?.html }}
      />
    </section>
  );
}

export const query = graphql`
fragment pageNavigationGridFields on PrismicPageDataBodyNavigationGrid {
  id, slice_type, slice_label

  primary {
    contentBefore { html }
    contentAfter { html }
  }

  items {
    image1 { gatsbyImageData, alt }
    link { url }
    cardContent { html, text }
    ctaText
  }
}

fragment articlesNavigationGridFields on PrismicArticlesDataBodyNavigationGrid {
  id, slice_type, slice_label

  primary {
    contentBefore { html }
    contentAfter { html }
  }

  items {
    image1 { gatsbyImageData, alt }
    link { url }
    cardContent { html, text }
    ctaText
  }
}
`;
