import React from 'react';
import { Tooltip } from '@mui/material';
import './styles.scss';

export function FwrdTooltip ({
  children,
  title,
  placement
}) {
  return (
    <div className="fwrd-tooltip">
      <Tooltip
        title={title}
        placement={placement}>
        {children}
      </Tooltip>
    </div>
  );
}
