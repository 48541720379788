import React from 'react';
import { Pagination } from '@mui/material';

export function BasicPagination ({ className, ...props }) {
  return (
    <Pagination
      className={`fwrd-pagination ${className ?? ''}`}
      {...props}
      size="small"
      shape="rounded"
    />
  );
}
