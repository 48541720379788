import React, { useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { Img } from '@maxmedia/mxm-react-lib';
import { ContentAccordion, MenuDropdown, Card } from '../../../../components';
import './styles.scss';

export function FAQs ({ primary, items }) {
  const [ activeAccordion, setActiveAccordion ] = useState(null);
  const [ activeGroup, setActiveGroup ] = useState(primary.group1);

  const { content } = primary;

  const faqTitle = !activeGroup ? null : (
    <div className='question-title'>{activeGroup}</div>
  );

  const desktopCards = [ 1, 2, 3, 4 ].map((i) => {
    if (!primary[`group${i}`]) return null;

    const cardIcon = !primary[`group${i}Icon`].gatsbyImageData ? null : (
      <Img image={(primary[`group${i}Icon`])} alt='' className="img-define" loading="eager" objectFit="contain" />
    );

    const altCardIcon = !primary[`group${i}AltIcon`].gatsbyImageData ? null : (
      <Img image={(primary[`group${i}AltIcon`])} alt='' className="alt-img-define"
        loading="eager" objectFit="contain" />
    );

    return (
      <Card
        key={i}
        className= 'small'
        topIcon={cardIcon}
        altIcon={altCardIcon}
        heading = {(primary[`group${i}`])}
        onClick={() => setActiveGroup(primary[`group${i}`])}
      />
    );
  }).filter(g => g);

  const desktopSelector = (
    <div className="desktop-selector">{desktopCards}</div>
  );

  const menuListItems = [ 1, 2, 3, 4 ].map((i) => {
    if (!primary[`group${i}`]) return null;

    return (
      { handleClick: () => setActiveGroup(primary[`group${i}`]), text: primary[`group${i}`] }
    );
  }).filter(g => g);

  const mobileSelector = !activeGroup ? null : (
    <div className="mobile-selector">
      <MenuDropdown
        buttonText={activeGroup}
        listItems={menuListItems}
      /></div>
  );

  const faqsRendered = useMemo(() => {
    if (!items?.length) return null;
    const itemsToRender = !activeGroup ? items : items.filter(i => i.group === activeGroup);

    return itemsToRender.map((item, i) => {
      const {
        question,
        answer
      } = item;

      const id = `question-${i}`;

      const handleAccordionChange = (qId) => (ev, isExpanded) => {
        setActiveAccordion(isExpanded ? qId : null);
      };

      const heading = ( <div dangerouslySetInnerHTML={{ __html: question.html }} /> );

      return (
        <ContentAccordion
          key={id}
          heading={heading}
          id={id}
          expanded={activeAccordion === id}
          onChange={handleAccordionChange(id)}
        >
          <div dangerouslySetInnerHTML={{ __html: answer.html }} />
        </ContentAccordion>
      );
    });
  }, [ items, activeAccordion, activeGroup ]);

  return (
    <section className="slice-module faqs-slice">
      <div className="faqs-slice__inner container --small">
        <div className="content-container">
          <div
            className="body-content"
            dangerouslySetInnerHTML={{ __html: content.html }}
          />
          {mobileSelector}
          {desktopSelector}
        </div>
        <div className="faq-list">
          {faqTitle}
          {faqsRendered}
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment pageFaqsFields on PrismicPageDataBodyFaqs {
  id, slice_type

  primary {
    content { html }
    group1
    group1Icon { gatsbyImageData }
    group1AltIcon { gatsbyImageData }
    group2
    group2Icon { gatsbyImageData }
    group2AltIcon  { gatsbyImageData }
    group3
    group3Icon { gatsbyImageData }
    group3AltIcon  { gatsbyImageData }
    group4
    group4Icon { gatsbyImageData }
    group4AltIcon  { gatsbyImageData }
  }

  items {
    group
    question { html }
    answer { html }
  }
}
`;
